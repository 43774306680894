import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import swal from 'sweetalert2';
import Api from "../util/api";
import Util from "../util/util";
import Resources from "../util/resources";
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-buttons/js/buttons.print';
import * as Icon from 'react-bootstrap-icons';

import ModalNuevoProductoProduccion from "./modal/NuevoProductoProduccion";
import ModalNuevoProcesoProduccion from "./modal/NuevoProcesoProduccion";
import useLoading from "../hooks/useLoading";


function OrganizacionCrearEditar(props) {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        organizacion: {
            nombre: "",
            nombre_razon_social: "",
            telefono: "",
            semblanza: "",
        },
        organizacionId: "",
        planta_produccion: null,
        showModalNuevaTarjeta: false,
        planta_produccion_id: "",
        loading: true,
        admin: false,
        tipoOrganizacion: null,
        procesoAEditar: null,
        productoAEditar: null,
        certificacionAEditar: null,
        plantaAEditar: null
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            organizacion: {
                ...prevState.organizacion,
                [id]: value,
            },
        }));
    };

    async function consultarProductos() {
        try {
            if (!state.organizacionId) return;
            startLoading();
            var res = await Api.productos_produccion.list(state.organizacionId);
            if (!await Util.requestSuccess(res)) {
                stopLoading();
                await swal.fire("¡Ups!", "Error al consultar productos de " + state.organizacion.nombre, "error");
                
                return;
            }
            var data = await res.json();

            console.log(data.productos);
            setState((prevState) => ({
                ...prevState,
                productos: data.productos,
            }));
        } catch (e) {
            alert("Catch consultar productos");
            console.log(e);
            stopLoading();
            await swal.fire("¡Ups!", "Error al consultar productos de " + state.organizacion.nombre, "error");
        }
        initDatatableProductos();
        stopLoading();
    }

    async function consultarProcesosProduccion() {
        try {
            if (!state.organizacionId) return;
            startLoading();
            var res = await Api.procesos_produccion.list(state.organizacionId);
            if (!await Util.requestSuccess(res)) {
                await swal.fire("¡Ups!", "Error al consultar procesos de producción de " + state.organizacion.nombre, "error");
                stopLoading();
                return;
            }
            var data = await res.json();
            console.log(data);
            setState((prevState) => ({
                ...prevState,
                procesos: data.procesos,
            }));
        } catch (e) {
            console.log(e);
            stopLoading();
            await swal.fire("¡Ups!", "Error al consultar procesos de producción de " + state.organizacion.nombre, "error");
        }
        initDatatableProcesos();
        stopLoading();
    }

    async function consultarPlantasProduccion() {
        try {
            if (!state.organizacionId) return;
            startLoading();
            var res = await Api.plantas_produccion.listOrganizacion(state.organizacionId);
            if (!await Util.requestSuccess(res)) {
                stopLoading();
                await swal.fire("¡Ups!", "Error al consultar plantas de producción de " + state.organizacion.nombre, "error");
                return;
            }
            var data = await res.json();
            console.log(data);
            setState((prevState) => ({
                ...prevState,
                plantas_produccion: data.plantas,
            }));
        } catch (e) {
            console.log(e);
            stopLoading();
            await swal.fire("¡Ups!", "Error al consultar plantas de producción de " + state.organizacion.nombre, "error");
        }
        initDatatablePlantasProduccion();
        stopLoading();
    }

    async function consultarCertificacionesProduccion() {
        try {
            if (!state.organizacionId) return;
            startLoading();
            var res = await Api.certificaciones_produccion.listOrganizacion(state.organizacionId);
            if (!await Util.requestSuccess(res)) {
                stopLoading();
                await swal.fire("¡Ups!", "Error al consultar certificaciones de producción de " + state.organizacion.nombre, "error");
                return;
            }
            var data = await res.json();
            console.log(data);
            setState((prevState) => ({
                ...prevState,
                certificaciones_produccion: data.certificaciones,
            }));
        } catch (e) {
            console.log(e);
            stopLoading();
            await swal.fire("¡Ups!", "Error al consultar certificaciones de producción de " + state.organizacion.nombre, "error");
        }
        initDatatableCertificaciones();
        stopLoading();
    }

    useEffect(() => {
        async function consultarOrganizacion(organizacionId) {
            startLoading();
            try {
                var res = await Api.organizaciones.get(organizacionId);
                await Util.requestSuccess(res);
                res = await res.json();
                console.log(res);
                var tipoOrganizacion;
                var organizacion = res.organizacion;
                if (organizacion.piso === 0) {
                    tipoOrganizacion = "Empresa privada";
                } else if (organizacion.piso === 1) {
                    tipoOrganizacion = "Fundación de primer piso";
                } else if (organizacion.piso === 2) {
                    tipoOrganizacion = "Fundación de segundo piso";
                }
                setState((prevState) => ({
                    ...prevState,
                    admin: res.admin,
                    organizacion: res.organizacion,
                    organizacionId: organizacionId,
                    tipoOrganizacion,
                }));
            } catch (e) {
                console.log(e);
            }
            stopLoading();
        }


        async function validarPermisoAccesoUsuario() {
            /*if (!props.user.tipo_usuario) return;
            if (props.user.tipo_usuario === "organizacion") {
                await swal.fire("Ups!", "¡No tienes permisos para entrar aquí!", "error");
                window.location.href = "/app/home";
                return;
            }*/
            consultarOrganizacion();
        }
        //validarPermisoAccesoUsuario();

        if (state.organizacionId.length === 0) {
            var initialId = window.location.pathname.replace(
                "/app/organizaciones/editar",
                ""
            );
            if (initialId.length > 0) {
                consultarOrganizacion(initialId.replace("/", ""));
            }
        } else {
            //renderCausas();
        }


    }, [props.user.tipo_usuario, state.organizacionId]);

    const updateApiKeyClick = async () => {
        try {
            if (!state.organizacionId) {
                await swal.fire("¡Ups!", "No se encontró organización, no es posible actualizar tu API Key", "error");
                return;
            }
            var confirm = await swal.fire({
                title: '¿Estás seguro que deseas actualizar el API Key de ' + state.organizacion.nombre + '?',
                text: "Si lo haces, borrarás el API key anterior",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar'
            });
            if (!confirm.isConfirmed) return;
            startLoading();
            var res = await Api.organizaciones.actualizarApiKey(state.organizacionId);
            var data = await res.json();
            if (!await Util.requestSuccess(res)) {
                await swal.fire("¡Ups!", data.msg, "error");
                stopLoading();
                return;
            }
            var api_key = data.api_key;
            await swal.fire("¡Listo!", 'El nuevo API Key de ' + state.organizacion.nombre + ' es "' + api_key + '". Es importante que no lo compartas. En caso de perdida o robo, puedes actualizarlo las veces que quieras.', "success");

        } catch (e) {
            console.log(e);
            await swal.fire("¡Ups!", "Error al actualizar el API Key de " + state.organizacion.nombre, "error");
        }
        stopLoading();
    };

    const saveClick = async () => {
        try {
            startLoading();
            var res;
            if (!state.organizacionId) {
                res = await Api.organizaciones.crear(state.organizacion);
            } else {
                res = await Api.organizaciones.actualizar(state.organizacionId, state.organizacion);
            }

            var data = await res.json();
            if (!await Util.requestSuccess(res)) {
                await swal.fire("¡Ups!", data.msg, "error");
                stopLoading();
                return;
            }
            if (!state.organizacionId) {
                window.location.href = "/app/mis-organizaciones";
            } else {
                window.location.href = "/app/organizaciones/"+state.organizacionId;
            }

        } catch (e) {
            console.log(e);
            await swal.fire("¡Ups!", "Error al guardar organización", "error");
        }
        stopLoading();
    };

    function closeModals() {
        setState((prevState) => ({
            ...prevState,
            showModalNuevoProducto: false,
            showModalNuevoProceso: false,
            showModalNuevaPlantaProduccion: false,
            showModalNuevaCertificacion: false,
        }));
    }

    function openModalProducto(indexProductoEditar = null) {
        var productoAEditar = null;
        console.log(indexProductoEditar);
        if (indexProductoEditar != null) {
            productoAEditar = state.productos[indexProductoEditar];
        }
        setState((prevState) => ({
            ...prevState,
            showModalNuevoProducto: true,
            productoAEditar
        }));
    }

    function openModalNuevoProceso() {
        setState((prevState) => ({
            ...prevState,
            showModalNuevoProceso: true,
        }));
    }

    function openModalNuevaPlantaProduccion() {
        setState((prevState) => ({
            ...prevState,
            showModalNuevaPlantaProduccion: true,
        }));
    }

    function openModalNuevaCertificacion() {
        setState((prevState) => ({
            ...prevState,
            showModalNuevaCertificacion: true,
        }));
    }

    function initDatatableProcesos() {
        $("#procesos_table").DataTable({
            ...Resources.datatableDefaultConfig,
            /*            columnDefs: [
                {
                    "targets": [3, 4, 5],
                    "visible": false,
                }
            ],
            buttons: [
                {
                    extend: 'copyHtml5',
                    exportOptions: {
                        columns: [0, ':visible']
                    }
                },
                {
                    extend: 'excelHtml5',
                    title: 'Lista de escuelas',
                    exportOptions: {
                        //columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                    }
                },
                {
                    extend: 'pdfHtml5',
                    title: 'Lista de escuelas',
                    exportOptions: {
                        //columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                    },
                    customize: function (doc) {
                        //Resources.customizePdf(doc, 6);
                    }
                }
            ], */
        });
    }

    function initDatatableProductos() {
        $("#productos_table").DataTable({
            ...Resources.datatableDefaultConfig,
        });
    }

    function initDatatableCertificaciones() {
        $("#certificaciones_table").DataTable({
            ...Resources.datatableDefaultConfig,
        });
    }

    function initDatatablePlantasProduccion() {
        $("#plantas_table").DataTable({
            ...Resources.datatableDefaultConfig,
        });
    }


    return (
        <div className="OrganizacionCrearEditar" style={{ width: "100%", minHeight: "92vh" }}>
            <main className="overflow-hidden bg-6">
                <header className="page header">
                    <div id="modalNuevaActividad" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title ml-auto">Nueva actividad</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <input type="text" placeholder="Nombre" name="nombre" id="txtNombreActividad" />
                                    <input type="text" placeholder="Descripción" name="nombre" id="txtDescripcionActividad" />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-default" id="cmdGuardarProceso">Guardar</button>
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <ModalNuevoProcesoProduccion
                        show={state.showModalNuevoProceso}
                        onHide={closeModals}
                        onLoading={props.onLoading}
                        organizacion={state.organizacionId}
                        procesoAEditar={state.procesoAEditar}
                    //producto={state.producto}
                    />
                    <ModalNuevoProductoProduccion
                        show={state.showModalNuevoProducto}
                        onHide={closeModals}
                        onLoading={props.onLoading}
                        organizacion={state.organizacionId}
                        productoAEditar={state.productoAEditar}
                    //producto={state.producto}
                    />
                    <div id="modalNuevaCertificacion" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title ml-auto">Nueva certificación</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <input type="text" placeholder="Nombre" name="nombre" id="txtNombreCertificacion" />
                                    <input type="text" placeholder="Descripción" name="nombre" id="txtDescripcionCertificacion" />
                                    Selecciona una vigencia: <input type="date" name="vigencia" id="txtVigenciaCertificacion" /><br />
                                    <a id="hrefCertificacion" href=" #" style={{ display: "none" }}>
                                        <p>Ver archivo actual</p>
                                    </a>
                                    Selecciona un archivo: <input type="file" name="vigencia" id="txtArchivoCertificacion" />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-default" id="cmdGuardarCertificacion">Guardar</button>
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div id="modalNuevaPlantaProduccion" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title ml-auto">Nueva planta de producción</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <input type="text" placeholder="Nombre" name="nombre" id="txtNombrePlantaProduccion" />
                                    <input type="text" placeholder="Dirección" name="nombre" id="txtDireccionPlantaProduccion" />
                                    <input type="text" placeholder="Teléfono" name="nombre" id="txtTelefonoPlantaProduccion" />
                                    <hr style={{ width: "100%" }} />
                                    <p>Administradores: </p>
                                    <div className="row">
                                        <div className="col-md-9 com-xs-9">
                                            <input type="text" placeholder="Email de nuevo administrador" name="email"
                                                id="txtNuevoAdminPlantaProduccion" />
                                        </div>
                                        <div className="col-md-3 com-xs-3">
                                            <button type="button" className="btn btn-default"
                                                id="cmdBuscarAdminPlantaProduccion">Buscar</button>
                                        </div>
                                    </div>
                                    <div id="listaAdministradoresPlantaProduccion">
                                    </div>
                                    <hr style={{ width: "100%" }} />
                                    <p>Procesos: </p>
                                    <div id="containerCheckProcesos"></div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-default" id="cmdGuardarPlantaProduccion">Guardar</button>
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="content">
                        <div className="row">
                            <div className="col-md-9">
                                <h1 id="titlePage" className="title">Nueva organización</h1>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="content">
                    <div className="container">
                        <form className="form">
                            <Tabs>
                                <TabList>
                                    <Tab>Información general</Tab>
                                    <Tab onClick={consultarProcesosProduccion} hidden={state.organizacionId === ""}>Procesos de producción</Tab>
                                    <Tab onClick={consultarProductos} hidden={state.organizacionId === ""}>Productos</Tab>
                                    <Tab onClick={consultarCertificacionesProduccion} hidden={state.organizacionId === ""}>Certificaciones</Tab>
                                    <Tab onClick={consultarPlantasProduccion} hidden={state.organizacionId === ""}>Plantas de producción</Tab>
                                </TabList>

                                <TabPanel>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img id="fotoOrganizacion" src="" alt="Foto de perfil" style={{ width: "100%", maxHeight: "300px" }} />
                                            <div className="row" style={{ marginTop: "15px", marginBottom: "15px" }}>
                                                <div className="col-md-12 col-xs-12" id="cambiarFotoContainer">
                                                    <button id="cmdCambiarFoto">Subir foto</button>
                                                </div>
                                                <div className="col-md-6 col-xs-6" style={{ display: "none" }} id="cancelarFotoContainer">
                                                    <button id="cmdCancelarFoto">Cancelar</button>
                                                </div>
                                            </div>

                                            <input type="file" id="inputFotoOrganizacion" accept="image/png, image/jpeg"
                                                style={{ display: "none" }} />

                                            <p id="nombreArchivoValidarRfc">Para activar la organización, es necesario validar su RFC
                                                subiendo tu constancia o cédula fiscal que contenga un código QR.La puedes descargar <a
                                                    href="https://www.sat.gob.mx/aplicacion/53027/genera-tu-constancia-de-situacion-fiscal."
                                                    target="_blank" rel="noreferrer"> aquí</a>.</p>
                                            <div className="row" id="validarRfcGeneralContainer" style={{ marginTop: "15px", marginBottom: "15px" }}>
                                                <div className="col-md-12 col-xs-12" id="validarRfcContainer">
                                                    <button id="cmdValidarRfc">Validar RFC</button>
                                                </div>
                                                <div className="col-md-6 col-xs-6" style={{ display: "none" }} id="cancelarValidarRfcContainer">
                                                    <button id="cmdCancelarValidarRfc">Cancelar</button>
                                                </div>
                                            </div>

                                            <input type="file" id="validarRfcFile" placeholder="Nombre" name="avatar"
                                                accept="image/jpeg, image/png" style={{ display: "none" }} />



                                        </div>
                                        <div className="col-md-6">
                                            <input type="text" placeholder="Nombre comercial" id="nombre" value={state.organizacion.nombre} onChange={handleChange} />
                                            <input type="text" placeholder="Nombre de Razón social"
                                                id="nombre_razon_social" value={state.organizacion.nombre_razon_social} onChange={handleChange} />
                                            <input type="text" placeholder="Telefono" name="telefono" id="telefono" value={state.organizacion.telefono} onChange={handleChange} />
                                            <div className="row" id="emailValidadoContainer"
                                                style={{ display: "none", margin: "0 0 15px", padding: "15px" }}>
                                                <div className="col-md-8 col-xs-8">
                                                    <p id="lblEmail"></p>
                                                </div>
                                                <div className="col-md-4 col-xs-4">
                                                    <button id="cmdCambiarEmail">Cambiar</button>
                                                </div>
                                            </div>
                                            <input type="text" placeholder="Email" name="email" id="email" value={state.organizacion.email} onChange={handleChange} />
                                            <input type="text" placeholder="Rfc" name="rfc" id="rfc" value={state.organizacion.rfc} onChange={handleChange} />
                                            <p>Tipo de organización: </p>
                                            <select id="piso" value={state.organizacion.piso} onChange={handleChange}>
                                                <option value="0">Empresa</option>
                                                <option value="1">Fundación de Primer piso</option>
                                                <option value="2">Fundación de Segundo piso</option>
                                            </select>
                                            <textarea placeholder="Semblanza o descripción de la organización" name="semblanza"
                                                id="semblanza" rows="5" value={state.organizacion.semblanza} onChange={handleChange}></textarea>
                                            <input type="text" placeholder="URL oficial de la fundación (opcional)" name="url_oficial"
                                                id="url_oficial" value={state.organizacion.url_oficial} onChange={handleChange} />

                                        </div>
                                    </div>
                                    <hr style={{ width: "100%" }} />
                                    <p>Lista de administradores</p>
                                    <div className="row">
                                        <div className="col-md-9 col-xs-9">
                                            <input type="text" placeholder="Email de nuevo administrador" name="emailNuevoAdministrador"
                                                id="emailNuevoAdministrador" />
                                            <ul className="email-group">
                                            </ul>
                                        </div>
                                        <div className="col-md-3 col-xs-3">
                                            <button id="cmdNuevoAdministrador">Agregar</button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-7 col-xs-7">
                                            Nombre
                                        </div>
                                        <div className="col-md-3 col-xs-3">
                                            Permisos
                                        </div>
                                        <div className="col-md-2 col-xs-2">
                                            Borrar
                                        </div>
                                    </div>
                                    <div id="listaAdministradoresOrganizacion">
                                    </div>
                                    <p id="msgReg"></p>
                                </TabPanel>
                                <TabPanel>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-9 col-xs-9">
                                                <p>Aquí puedes registrar los procesos de producción realizados en tu organización.</p>
                                            </div>
                                            <div className="col-md-3 col-xs-3">
                                                <button id="cmdNuevoProceso" onClick={openModalNuevoProceso}>Agregar nuevo proceso</button>
                                            </div>
                                        </div>
                                        <hr style={{ width: "100%" }} />
                                        <div style={{ "width": "100%", "textAlign": "center" }}>
                                            <table id="procesos_table" className="mdl-data-table table-striped" style={{ "width": "100%", "textAlign": "center" }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ textAlign: "center" }}>Nombre</th>
                                                        <th style={{ textAlign: "center" }}>Descripción</th>
                                                        <th style={{ textAlign: "center" }}>Editar/Eliminar</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ "width": "100%" }}>
                                                    {state.procesos &&
                                                        state.procesos.map((proceso) => (
                                                            <tr id={"proceso" + proceso._id} key={"proceso" + proceso._id}>
                                                                <td> {proceso.nombre} </td>
                                                                <td> {proceso.descripcion} </td>
                                                                <td>
                                                                    <span type="button" className="btn btn-default col-md-2" title="Editar" onClick={() => alert("Editar")}>
                                                                        <Icon.Pencil />
                                                                    </span>
                                                                    <span type="button" className="btn btn-default col-md-2" title="Eliminar" onClick={() => alert("Eliminar")}>
                                                                        <Icon.Trash />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-9 col-xs-9">
                                                <p>Aquí puedes gestionar los productos realizados en tu organización.
                                                    Después podrás dar trazabilidad a tus clientes.</p>
                                            </div>
                                            <div className="col-md-3 col-xs-3">
                                                <button id="cmdNuevoProducto" onClick={openModalProducto}>Agregar nuevo producto</button>
                                            </div>
                                        </div>
                                        <table id="productos_table" className="mdl-data-table table-striped" style={{ "width": "100%", "textAlign": "center" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "center" }}>Nombre</th>
                                                    <th style={{ textAlign: "center" }}>Descripción</th>
                                                    <th style={{ textAlign: "center" }}>Editar/Eliminar</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ "width": "100%" }}>
                                                {state.productos &&
                                                    state.productos.map((producto, indexArray) => (
                                                        <tr id={"producto" + producto._id} key={"producto" + producto._id}>
                                                            <td> {producto.nombre} </td>
                                                            <td> {producto.descripcion} </td>
                                                            <td>
                                                                <span type="button" className="btn btn-default col-md-2" title="Editar" onClick={() => openModalProducto(indexArray)}>
                                                                    <Icon.Pencil />
                                                                </span>
                                                                <span type="button" className="btn btn-default col-md-2" title="Eliminar" onClick={() => alert("Eliminar")}>
                                                                    <Icon.Trash />
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-9 col-xs-9">
                                                <p>Aquí puedes registrar tus certificaciones de producción.</p>
                                            </div>
                                            <div className="col-md-3 col-xs-3">
                                                <button id="cmdNuevaCertificacion" onClick={openModalNuevaCertificacion}>Nueva certificación</button>
                                            </div>
                                        </div>
                                        <table id="certificaciones_table" className="mdl-data-table table-striped" style={{ "width": "100%", "textAlign": "center" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "center" }}>Nombre</th>
                                                    <th style={{ textAlign: "center" }}>Descripción</th>
                                                    <th style={{ textAlign: "center" }}>Vigencia</th>
                                                    <th style={{ textAlign: "center" }}>Editar/Eliminar</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ "width": "100%" }}>
                                                {state.certificaciones &&
                                                    state.certificaciones.map((certificacion) => (
                                                        <tr id={"certificacion" + certificacion._id} key={"certificacion" + certificacion._id}>
                                                            <td> {certificacion.nombre} </td>
                                                            <td> {certificacion.descripcion} </td>
                                                            <td> {certificacion.vigencia} </td>
                                                            <td>
                                                                <span type="button" className="btn btn-default col-md-2" title="Editar" onClick={() => alert("Editar")}>
                                                                    <Icon.Pencil />
                                                                </span>
                                                                <span type="button" className="btn btn-default col-md-2" title="Eliminar" onClick={() => alert("Eliminar")}>
                                                                    <Icon.Trash />
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>

                                </TabPanel>
                                <TabPanel>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-9 col-xs-9">
                                                <p>Aquí puedes registrar tus plantas de producción.</p>
                                            </div>
                                            <div className="col-md-3 col-xs-3">
                                                <button id="cmdNuevaPlantaProduccion" onClick={openModalNuevaPlantaProduccion}>Nueva planta</button>
                                            </div>
                                        </div>
                                        <table id="plantas_table" className="mdl-data-table table-striped" style={{ "width": "100%", "textAlign": "center" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "center" }}>Nombre</th>
                                                    <th style={{ textAlign: "center" }}>Dirección</th>
                                                    <th style={{ textAlign: "center" }}>Teléfono</th>
                                                    <th style={{ textAlign: "center" }}>Editar/Eliminar</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ "width": "100%" }}>
                                                {state.plantas_produccion &&
                                                    state.plantas_produccion.map((planta) => (
                                                        <tr id={"planta" + planta._id} key={"planta" + planta._id}>
                                                            <td> {planta.nombre} </td>
                                                            <td> {planta.direccion} </td>
                                                            <td> {planta.telefono} </td>
                                                            <td>
                                                                <span type="button" className="btn btn-default col-md-2" title="Editar" onClick={() => alert("Editar")}>
                                                                    <Icon.Pencil />
                                                                </span>
                                                                <span type="button" className="btn btn-default col-md-2" title="Eliminar" onClick={() => alert("Eliminar")}>
                                                                    <Icon.Trash />
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </TabPanel>
                            </Tabs>

                            <div className="row mt-4">
                                <div className="col-md-4">
                                    <button id="cmdCancelar">Cancelar</button>
                                </div>
                                <div className="col-md-4">
                                    <button id="cmdNuevaOrganizacion" onClick={saveClick} style={{ background: "#1ca1a4" }}>Guardar organización</button>
                                </div>
                                <div className="col-md-4">
                                    <button id="cmdNuevoApiKey" onClick={updateApiKeyClick} hidden={state.organizacionId === ""}>Generar/actualizar Api Key<br />(Sólo desarrolladores)</button>
                                </div>
                            </div>


                        </form>

                    </div>
                </div>
            </main>
        </div>
    );
}

export default OrganizacionCrearEditar;
