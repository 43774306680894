import React, { useEffect, useState } from "react";
import swal from 'sweetalert2';
import CardOrganizacion from "./CardOrganizacion";
import Api from "../util/api";
import Util from "../util/util";
function MisOrganizaciones(props) {
  const [state, setState] = useState({
    mis_organizaciones: null,
    loading: true,
  });
  useEffect(() => {
    async function fetchData() {
      if(!Util.validarJWT()){
        await swal.fire("¡Ups!", "Es necesario iniciar sesión", "error");
        window.location.href = "/login";
        return;
      }
      if (props.user._id === "") return;
      try {
        props.onLoading(true);
        var res = await Api.organizaciones.mis_organizaciones();
        var organizaciones = (await res.json()).organizaciones;
        if (await Util.requestSuccess(res)) {
          setState({
            mis_organizaciones: organizaciones,
            loading: false,
          });
        }
        console.log(res);
      } catch (e) {
        console.log(e);
      }
      props.onLoading(false);
    }
    fetchData();
  }, [props.user._id]);
  return (
    <div className="MisOrganizaciones" style={{ width: "100%", minHeight: "92vh" }}>
      <main className="overflow-hidden bg-6">
        <header className="page header">
          <div className="content">
            <div className="row">
              <div className="col-md-9 col-xs-9">
                <h1 className="title">Mis organizaciones</h1>
              </div>
              <div className="col-md-3 col-xs-3">
                <nav className="navigation" style={{backgroundColor:"rgba(0,0,0,0)"}}>
                  <a
                    id="cmdNuevaOrganizacion"
                    className="btn btn-rounded btn-solid mr-3 px-3 mt-2"
                    href="/app/organizaciones/nuevo"
                  >
                    <i className="fas fa-sign-in-alt d-none d-md-inline mr-md-0 mr-lg-2"></i>
                    <span className="d-md-none d-lg-inline">
                      Crear nueva organización
                    </span>
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </header>
        <div className="content">
          <div className="container">
            <div className="row" id="fundacionesContainer">
              {state.mis_organizaciones &&
                state.mis_organizaciones.map((organizacion) => (
                  <CardOrganizacion
                    organizacion={organizacion}
                    key={organizacion._id}
                  />
                ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default MisOrganizaciones;
