import React, { useEffect, useState } from "react";
import AppHeader from "./AppHeader";
import AppLeftMenu from "./AppLeftMenu";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import Perfil from "./Perfil";
import PerfilEditar from "./PerfilEditar";
import DocumentosLista from "./DocumentosLista";
import DocumentosValidar from "./DocumentosValidar";
import DocumentosComprar from "./DocumentosComprar";
import MisOrganizaciones from "./MisOrganizaciones";
import MisPlantasProduccion from "./MisPlantasProduccion";
import ListaFundaciones from "./ListaFundaciones";
import ListaCausas from "./ListaCausas";
import MisDonaciones from "./MisDonaciones";
import Organizacion from "./Organizacion";
import OrganizacionCrearEditar from "./OrganizacionCrearEditar";
import PlantaProduccion from "./PlantaProduccion";
import TarjetaProduccion from "./TarjetaProduccion";
import BuscarTarjetaProduccion from "./BuscarTarjetaProduccion";
import Causa from "./Causa";
import Page404 from "./../pages/error/Error404";
import SeguimientoDonativos from "./SeguimientoDonativos";

function AppContainer(props) {
  const [state, setState] = useState({
    user: {
      _id: "",
      nombre: "",
      foto: "",
      apellidos: "",
    },
    loading: false
  });

  const handleLoading = (loadingValue) => {
    setState((prevState) => ({
      ...prevState,
      loading: loadingValue
    }));
  }

  useEffect(() => {
    var value = localStorage.getItem("LCJWT");
    var jsonUser = null;
    if (value && value !== "undefined" && value !== null) {
      jsonUser = JSON.parse(
        decodeURIComponent(escape(atob(value.split(".")[1])))
      );
      setState((prevState) => ({
        ...prevState,
        user: {
          _id: jsonUser._id,
          nombre: jsonUser.nombre,
          apellidos: jsonUser.apellidos,
          foto: jsonUser.foto,
        },
      }));
    }
  }, []);

  return (
    <div className="AppContainer">
      <AppHeader user={state.user}  />
      <div className="main-body">
        <AppLeftMenu />
        <div style={{ width: "100%", minHeight: "90vh" }}>
          
          <BrowserRouter>
            <Switch>
              <Route exact path="/app/mi-perfil">
                <Perfil  user={state.user} />
              </Route>
              <Route exact path="/app/mi-perfil/editar/:id">
                <PerfilEditar  user={state.user} />
              </Route>
              <Route exact path="/app/documentos">
                <DocumentosLista  user={state.user} />
              </Route>
              <Route exact path="/app/documentos/validar">
                <DocumentosValidar  user={state.user} />
              </Route>
              <Route exact path="/app/documentos/precios">
                <DocumentosComprar  user={state.user} />
              </Route>
              <Route exact path="/app/documentos/validar/:id">
                <DocumentosValidar  user={state.user} />
              </Route>
              <Route exact path="/app/mis-organizaciones">
                <MisOrganizaciones  user={state.user} />
              </Route>
              <Route exact path="/app/fundaciones">
                <ListaFundaciones  user={state.user} />
              </Route>
              <Route exact path="/app/causas">
                <ListaCausas  user={state.user} />
              </Route>
              <Route exact path="/app/causas/:id">
                <Causa  user={state.user} />
              </Route>
              <Route exact path="/app/mis-donaciones">
                <MisDonaciones  user={state.user} />
              </Route>
              <Route exact path="/app/organizaciones/nuevo">
                <OrganizacionCrearEditar  user={state.user} />
              </Route>
              <Route exact path="/app/organizaciones/editar/:id">
                <OrganizacionCrearEditar  user={state.user} />
              </Route>
              <Route exact path="/app/organizaciones/:id">
                <Organizacion  user={state.user} />
              </Route>

              <Route exact path="/app/mis-plantas-produccion">
                <MisPlantasProduccion  user={state.user} />
              </Route>
              <Route exact path="/app/plantas-suministro/:id">
                <PlantaProduccion  user={state.user} />
              </Route>

              <Route exact path="/app/tarjetas-suministro/buscar">
                <BuscarTarjetaProduccion  user={state.user} />
              </Route>
              <Route exact path="/app/tarjetas-suministro/:id">
                <TarjetaProduccion  user={state.user} />
              </Route>

              <Route exact path="/app/transacciones/:id">
                <SeguimientoDonativos  user={state.user} />
              </Route>
              <Route component={Page404} />
            </Switch>
          </BrowserRouter>
      </div>
      {/*<AppGlobalMenu />*/}
    </div>
      {/*<AppFooter />*/ }
    </div >
  );
}

export default AppContainer;
