//import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CtlNuevoDocumento from "../CtlNuevoDocumento";
import Api from "../../util/api";
import Util from "../../util/util";
import { QrReader } from 'react-qr-reader';
import swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useLoading from "../../hooks/useLoading";
function NuevaTarjetaProduccion(props) {
    //const history = useHistory();
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        abrirCamara: false,
        tarjeta: {
            fecha_elaboracion: new Date(),
            fecha_consumo_preferente: null,
            fecha_caducidad: null,
            lote: "",
            producto: null,
            actividades: "Pasteurización"
        },
        documentos_a_certificar: null,
        estados_documentos_a_certificar: null,
        error: null,
        productos: [],
        materia_prima: []
    });

    const handleDateChangeTarjeta = (id, value) => {
        setState((prevState) => ({
            ...prevState,
            tarjeta: {
                ...prevState.tarjeta,
                [id]: value,
            }
        }));
    };

    const handleChangeTarjeta = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            tarjeta: {
                ...prevState.tarjeta,
                [id]: value,
            },
        }));
    };

    async function handleScan(data) {
        if (!data) return;
        setState({
            result: data,
        });

        //if(data.search("/app/documentos/validar/")<0) return;
        const idGeneric = data.split("/app/documentos/validar/")[1];
        setState((prevState) => ({
            ...prevState,
            idGeneric,
        }));
        //consultarDocumento(idGeneric);
    }

    async function handleError(err) {
        console.error(err);
        await swal.fire("¡Ups!", "Error al abrir cámara web", "error");
        setState((prevState) => ({
            ...prevState,
            abrirCamara: false,
        }));
    }

    useEffect(() => {
        async function consultarProductos() {
            try {
                if (!props.planta_produccion) return;
                if (!props.planta_produccion.propietario) return;
                if (!props.planta_produccion.propietario._id) return;
                startLoading();
                var res = await Api.productos_produccion.list(props.planta_produccion.propietario._id);
                if (!await Util.requestSuccess(res)) {
                    stopLoading();
                    await swal.fire("¡Ups!", "Error al consultar productos", "error");

                    return;
                }
                var data = await res.json();

                console.log(data.productos);
                setState((prevState) => ({
                    ...prevState,
                    productos: data.productos,
                    tarjeta: {
                        ...prevState.tarjeta,
                        planta_produccion: props.planta_produccion._id,
                        producto: data.productos[0]._id
                    }
                }));
            } catch (e) {
                console.log(e);
                stopLoading();
                await swal.fire("¡Ups!", "Error al consultar productos", "error");
            }
        }

        async function consultarProcesosProduccion() {
            try {
                if (!props.planta_produccion) return;
                if (!props.planta_produccion.propietario) return;
                if (!props.planta_produccion.propietario._id) return;
                startLoading();
                var res = await Api.procesos_produccion.list(props.planta_produccion.propietario._id);
                if (!await Util.requestSuccess(res)) {
                    stopLoading();
                    await swal.fire("¡Ups!", "Error al consultar procesos de producción", "error");

                    return;
                }
                var data = await res.json();
                console.log(data);
                setState((prevState) => ({
                    ...prevState,
                    procesos: data.procesos,
                }));
            } catch (e) {
                console.log(e);
                stopLoading();
                await swal.fire("¡Ups!", "Error al consultar procesos de producción", "error");
            }
        }

        consultarProductos();
        consultarProcesosProduccion();
    }, [props.planta_produccion]);

    function actualizarVigenciaDocumento(index, fechaVigencia) {
        state.documentos_a_certificar[index].vigencia = fechaVigencia;
        console.log(state.documentos_a_certificar[index].vigencia);
    }

    function actualizarPrivacidadDocumento(index, privado) {
        state.documentos_a_certificar[index].privado = privado;
        console.log(state.documentos_a_certificar[index].privado);
    }

    async function clickCrearTarjeta() {
        var confirm = await swal.fire({
            title: '¿Estás seguro?',
            text: "Una vez que la tarjeta sean certificada en Blockchain su información no podrá cambiar.",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            icon: "warning",
            buttons: {
                cancel: "Cancelar",
                ok: "Certificar",
            },
            dangerMode: true,
        });
        if (!confirm.isConfirmed) {
            return;
        }
        console.log(state.tarjeta);
        try {
            startLoading();
            var res = await Api.tarjetas_produccion.certificar(props.planta_produccion.propietario._id, state.tarjeta);
            if (!await Util.requestSuccess(res)) {
                stopLoading();
                await swal.fire("¡Ups!", "Error al guardar tarjeta de producción", "error");
                return;
            }
            await swal.fire("¡Listo!", "La tarjeta de producción fué guardada con exito, en breve podras ver la transacción en blockchain.", "success");
            console.log(res);

            //props.consultardocumentos();
            //Aqui no es necesario loading = false, props.consultardocumentos() ya lo hace
            props.onHide();
        } catch (e) {
            console.log(e);
            stopLoading();
            await swal.fire("¡Ups!", "Error al guardar tarjeta de producción", "error");
        }
    }

    const tempDate = new Date();
    const minDate = new Date(tempDate.setMonth(tempDate.getMonth() - 2))

    return (
        <div className="NuevaTarjetaProduccion">
            <Modal {...props} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Nueva Tarjeta de producción</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-2 col-xs-2"></div>
                        <div className="col-md-8 col-xs-8">
                            <form style={{ width: "100%" }} className="form">

                                <div className="row">
                                    <div className="col-md-6 col-xs-6">
                                        <label>Producto: </label>
                                        <select onChange={handleChangeTarjeta} id="producto">
                                            {state.productos && state.productos.map((producto, index) => (
                                                <option value={producto._id} key={producto._id}>{producto.nombre}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-6 col-xs-6">
                                        <label>Fecha de elaboración: </label>
                                        <DatePicker selected={state.tarjeta.fecha_elaboracion} id="fecha_elaboracion"
                                            dateFormat="dd/MM/yyyy" minDate={minDate} maxDate={Date.now()}
                                            onChange={date => handleDateChangeTarjeta("fecha_elaboracion", date)} />
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-6 col-xs-6">
                                        <label>Fecha de consumo preferente: </label>
                                        <DatePicker selected={state.tarjeta.fecha_consumo_preferente} id="fecha_consumo_preferente"
                                            dateFormat="dd/MM/yyyy" minDate={Date.now()}
                                            onChange={date => handleDateChangeTarjeta("fecha_consumo_preferente", date)} />
                                    </div>
                                    <div className="col-md-6 col-xs-6">
                                        <label>Fecha de caducidad: </label>
                                        <DatePicker selected={state.tarjeta.fecha_caducidad} id="fecha_caducidad"
                                            dateFormat="dd/MM/yyyy" minDate={Date.now()}
                                            onChange={date => handleDateChangeTarjeta("fecha_caducidad", date)} />
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-6 col-xs-6">
                                        <label>Número de Lote: </label>
                                        <input type="text" id="lote"
                                            placeholder="12345" onChange={handleChangeTarjeta} />
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-6 col-xs-6">
                                        <label>Actividades realizadas: </label>
                                        {state.procesos && state.procesos.map((proceso, index) => (
                                            <div>
                                                <input type="checkbox" key={proceso._id} id={proceso._id} />{" " + proceso.nombre}
                                            </div>

                                        ))}
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-12 col-xs-12">
                                        <label>Información extra: </label>
                                        <textarea rows="4" cols="50" id="actividades"
                                            placeholder="Información extra..." onChange={handleChangeTarjeta} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <br />
                    <p id="subtitle">Materia prima a utilizar.</p>
                    <div>
                        {state.abrirCamara && (
                            <QrReader
                                delay={300}
                                onError={handleError}
                                onScan={handleScan}
                                style={{ width: '100%' }}
                            />
                        )}
                    </div>
                    <p>{state.result}</p>
                    {state.materia_prima &&
                        state.materia_prima.length > 0 ? (
                        <div id="documentosPorSubirContainer">

                            <div className="row" style={{ background: "#ccc" }}>
                                <div className="col-md-4 col-xs-4">
                                    <p>Nombre</p>
                                </div>
                                <div className="col-md-2 col-xs-2">
                                    <p>Peso</p>
                                </div>
                                <div className="col-md-3 col-xs-3">
                                    <p>Vigencia</p>
                                </div>
                                <div className="col-md-1 col-xs-1">
                                    <p>Privado</p>
                                </div>
                                <div className="col-md-1 col-xs-1">
                                    <p>Estado</p>
                                </div>
                            </div>
                            <div id="filesContainer">
                            </div>
                        </div>
                    ) : (
                        <p>Aún no hay materia prima seleccionada</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={clickCrearTarjeta}>
                        Crear tarjeta
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default NuevaTarjetaProduccion;