import Api from "../util/api";
import React, { useState, useEffect } from "react";
import Util from "../util/util";
import ModalDocumentoEncontrado from "./modal/DocumentoEncontrado";
import ModalValidarDocumento from "./modal/ValidarDocumento";
import swal from 'sweetalert2';
import { QrReader } from 'react-qr-reader';
import useLoading from "../hooks/useLoading";

function DocumentosValidar(props) {
  const { startLoading, stopLoading } = useLoading();
  const [state, setState] = useState({
    abrirCamara: false,
    documentoEncontrado: null,
    showModalDocumentoEncontrado: false,
    showModalValidarDocumento: false,
    idGeneric: "",
  });

  function closeModals() {
    setState({
      ...state,
      showModalDocumentoEncontrado: false,
      showModalValidarDocumento: false,
    });
  }

  async function consultarDocumento(idGeneric) {
    if (idGeneric == null) idGeneric = state.idGeneric;
    if (idGeneric.length !== 66 && idGeneric.length !== 64 &&
      idGeneric.length !== 24 && idGeneric.length !== 36) return;
    try {
      startLoading();
      var res = await Api.documentos.get(idGeneric);
      if (!await Util.requestSuccess(res)) {
        stopLoading();
        await swal.fire("¡Ups!", "Archivo no encontrado", "error");

        return;
      }
      res = await res.json();
      setState((prevState) => ({
        ...prevState,
        documentoEncontrado: res,
        showModalDocumentoEncontrado: true,
      }));
      console.log(res);
    } catch (e) {
      console.log(e);
      stopLoading();
      await swal.fire("¡Ups!", "Existen problemas para consultar documento", "error");
    }
    stopLoading();
  }

  async function consultarDocumentoManual() {
    const idGeneric = state.idGeneric;
    console.log(idGeneric);
    console.log(idGeneric.length);
    consultarDocumento(idGeneric);
  }

  async function handleScan(data) {
    if (!data) return;
    setState({
      result: data,
    });

    if (data.search("/app/documentos/validar/") < 0) return;
    const idGeneric = data.split("/app/documentos/validar/")[1];
    setState((prevState) => ({
      ...prevState,
      idGeneric,
    }));
    consultarDocumento(idGeneric);
  }

  async function handleError(err) {
    console.error(err);
    await swal.fire("¡Ups!", "Error al abrir cámara web", "error");
    setState((prevState) => ({
      ...prevState,
      abrirCamara: false,
    }));
  }

  function abrirModalValidar() {
    setState((prevState) => ({
      ...prevState,
      showModalValidarDocumento: true,
    }));
  }
  const handleChangeGenericId = (e) => {
    setState((prevState) => ({
      ...prevState,
      idGeneric: e.target.value,
    }));
  };
  useEffect(() => {
    var initialId = window.location.pathname.replace(
      "/app/documentos/validar/",
      ""
    );
    if (initialId.length > 0) {
      setState((prevState) => ({
        ...prevState,
        idGeneric: initialId,
      }));
      consultarDocumento(initialId);
    }
  }, []);

  function abrirCerrarCamara() {
    setState((prevState) => ({
      ...prevState,
      abrirCamara: !prevState.abrirCamara,
    }));
  }

  const previewStyle = {
    height: 240,
    width: 320,
  }

  return (
    <main className="overflow-hidden bg-6" style={{ width: "100%", minHeight: "92vh" }}>
      <header className="page header">
        <ModalValidarDocumento
          show={state.showModalValidarDocumento}
          onHide={closeModals}
          onLoading={props.onLoading}
        />

        <ModalDocumentoEncontrado
          show={state.showModalDocumentoEncontrado}
          onHide={closeModals}
          documentoEncontrado={state.documentoEncontrado}
          onLoading={props.onLoading}
        />
        <div className="content">
          <div className="row">
            <div className="col-md-9 col-xs-9">
              <h3 className="title nombreOrganizacion">Validar documento</h3>
            </div>
          </div>
        </div>
      </header>
      <div className="content">
        <div className="container">
          <p>Existen 3 formas para validar un documento: </p>
          <div className="row form">
            <div className="col-md-4 col-xs-12">
              <p>
                1 - Ingresa el Folio de certificación del documento que
                quieres verificar.
              </p>
              <input
                type="text"
                placeholder="Folio de certificación / Tx Hash / Hash de documento"
                id="txtUID"
                onChange={handleChangeGenericId}
              />
              <button
                id="cmdConsultarUID"
                className="button"
                onClick={consultarDocumentoManual}
              >
                Consultar documento
              </button>
            </div>
            <div className="col-md-4 col-xs-12">
              <p>
                2 - Si tu documento cuenta con un código QR, puedes leerlo por
                medio de la cámara web.
              </p>
              <button onClick={abrirCerrarCamara} className="button">
                {(state.abrirCamara && "Cerrar cámara web") || "Abrir cámara web"}
              </button>

              <div>
                {state.abrirCamara && (
                  <QrReader
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: '100%' }}
                  />
                )}
              </div>
              <video id="preview" style={{ width: "100%" }}></video>
            </div>
            <div className="col-md-4 col-xs-12">
              <p>
                3 - Si tienes el archivo en digital, puedes subirlo para
                validarlo.
              </p>

              <button
                href="#"
                data-toggle="modal"
                data-target="#modalCertificarDocumentos"
                id="cmdSubirArchivo"
                className="button"
                onClick={abrirModalValidar}
              >
                Subir archivo
              </button>
            </div>
          </div>
          <div className="row"></div>
        </div>
      </div>
    </main>
  );
}

export default DocumentosValidar;
