import React, { useEffect, useState } from "react";
import CardPlantaProduccion from "./CardPlantaProduccion";
import Api from "../util/api";
import Util from "../util/util";
function MisPlantasProduccion(props) {
    const [state, setState] = useState({
        mis_plantas_produccion: null,
    });
    useEffect(() => {
        async function fetchData() {
            if (props.user._id === "") return;
            try {
                props.onLoading(true);
                var res = await Api.plantas_produccion.mis_plantas_produccion();
                var plantas = (await res.json()).plantas;
                if (await Util.requestSuccess(res)) {
                    setState({
                        mis_plantas_produccion: plantas,
                    });
                }
                console.log(plantas);
            } catch (e) {
                console.log(e);
            }
            props.onLoading(false);
        }
        fetchData();
    }, [props.user._id]);
    return (
        <div className="MisPlantasProduccion" style={{ width: "100%", minHeight: "92vh" }}>
            <main className="overflow-hidden bg-6">
                <header className="page header">
                    <div className="content">
                        <div className="row">
                            <div className="col-md-9 col-xs-9">
                                <h1 className="title">Mis plantas de producción</h1>
                            </div>
                            <div className="col-md-3 col-xs-3">
                                <nav className="navigation" style={{backgroundColor:"rgba(0,0,0,0)"}}>
                                    <a
                                        id="cmdNuevaOrganizacion"
                                        className="btn btn-rounded btn-solid mr-3 px-3 mt-2"
                                        href="# "
                                    >
                                        <i className="fas fa-sign-in-alt d-none d-md-inline mr-md-0 mr-lg-2"></i>
                                        <span className="d-md-none d-lg-inline">
                                            Crear nueva planta de producción
                                        </span>
                                    </a>
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="content">
                    <div className="container">
                        <div className="row" id="fundacionesContainer">
                            {state.mis_plantas_produccion &&
                                state.mis_plantas_produccion.map((planta) => (
                                    <CardPlantaProduccion
                                        planta={planta}
                                        key={planta._id}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default MisPlantasProduccion;
