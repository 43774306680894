import React from "react";
import Util from "../util/util";

function CardTarjetaProduccion(props) {
  return (
    <div className="CardTarjetaProduccion col-md-3 col-xs-6" style={{ padding: "10px" }}>
      <a href={"/app/tarjetas-suministro/" + props.tarjeta._id}>
        <div className="card">
          <img
            src={props.tarjeta.producto.foto}
            alt={props.tarjeta.nombre}
            style={{ width: "100%" }}
          />
          <div>
          <h5>{props.tarjeta.data}</h5>
          <h6>{props.tarjeta &&
              Util.formatDateShort(props.tarjeta.fecha_registro)}</h6>
          </div>
        </div>
      </a>
    </div>
  );
}

export default CardTarjetaProduccion;
