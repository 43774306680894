import React, { useEffect, useState } from "react";
import CardOrganizacion from "./CardOrganizacion";
import Api from "../util/api";
import Util from "../util/util";
function ListaFundaciones(props) {
  const [state, setState] = useState({
    fundaciones: null,
    loading: true,
  });
  useEffect(() => {
    async function fetchData() {
      try {
        props.onLoading(true);
        var res = await Api.organizaciones.listaFundaciones();
        var organizaciones = (await res.json()).organizaciones;
        console.log(organizaciones);
        if (await Util.requestSuccess(res)) {
          setState({
            fundaciones: organizaciones,
            loading: false,
          });
        }
        console.log(res);
      } catch (e) {
        console.log(e);
      }
      props.onLoading(false);
    }
    fetchData();
  }, []);
  return (

    <main className="overflow-hidden bg-6" style={{ width: "100%", minHeight: "92vh" }}>
      <header className="page header">
        <div className="content">
          <div className="row">
            <div className="col-md-9 col-xs-9">
              <h1 className="title">Fundaciones</h1>
            </div>
            <div className="col-md-3 col-xs-3">
              <nav className="navigation" style={{backgroundColor:"rgba(0,0,0,0)"}}>
                <a
                  id="cmdNuevaOrganizacion"
                  className="btn btn-rounded btn-solid mr-3 px-3 mt-2"
                  href="/"
                >
                  <i className="fas fa-sign-in-alt d-none d-md-inline mr-md-0 mr-lg-2"></i>
                  <span className="d-md-none d-lg-inline">
                    Crear nueva organización
                  </span>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div className="content">
        <div className="container">
          <div className="row" id="fundacionesContainer">
            {state.fundaciones &&
              state.fundaciones.map((organizacion) => (
                <CardOrganizacion
                  organizacion={organizacion}
                  key={organizacion._id}
                />
              ))}
          </div>
        </div>
      </div>
    </main>
  );
}

export default ListaFundaciones;
