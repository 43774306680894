import React, { useEffect, useState } from "react";
import Api from "../util/api";
import Util from "../util/util";
import CardCausa from "./CardCausa";

function Organizacion(props) {
  const [state, setState] = useState({
    organizacion: null,
    organizacionId: "",
    causas: null,
    loading: true,
    admin: false,
    tipoOrganizacion: null,
  });

  useEffect(() => {
    async function renderCausas() {
      if (state.organizacion.piso === 0) return;
      props.onLoading(true);
      try {
        var res = await Api.causas.listByOrganization(state.organizacion._id);
        await Util.requestSuccess(res);
        res = await res.json();
        console.log(res.causas);
        setState((prevState) => ({
          ...prevState,
          causas: res.causas,
        }));
      } catch (e) {
        
      }
      props.onLoading(false);
    }

    if (state.organizacionId.length === 0) {
      var initialId = window.location.pathname.replace(
        "/app/organizaciones",
        ""
      );
      if (initialId.length > 0) {
        consultarOrganizacion(initialId.replace("/", ""));
      }
    } else {
      renderCausas();
    }
  }, [state.organizacionId, state.organizacion]);

  async function consultarOrganizacion(organizacionId) {
    props.onLoading(true);
    try {
      var res = await Api.organizaciones.get(organizacionId);
      await Util.requestSuccess(res);
      res = await res.json();
      console.log(res);
      var tipoOrganizacion;
      var organizacion = res.organizacion;
      if (organizacion.piso === 0) {
        tipoOrganizacion = "Empresa privada";
      } else if (organizacion.piso === 1) {
        tipoOrganizacion = "Fundación de primer piso";
      } else if (organizacion.piso === 2) {
        tipoOrganizacion = "Fundación de segundo piso";
      }
      setState((prevState) => ({
        ...prevState,
        admin: res.admin,
        organizacion: res.organizacion,
        organizacionId: organizacionId,
        tipoOrganizacion,
      }));
    } catch (e) {
      console.log(e);
    }
    props.onLoading(false);
  }

  function renderEditarOrganizacion(props) {
    console.log(state.admin);
    if (state.admin === "FUND") {
      console.log(true);
      return (
        <nav className="navigation" style={{ backgroundColor: "rgba(0,0,0,0)" }}>
          <a
            id="cmdEditarOrganizacion"
            className="btn btn-rounded btn-solid mr-3 px-3 mt-2"
            href={
              (state.organizacion &&
                "/app/organizaciones/editar/" + state.organizacion._id) ||
              "# "
            }
          >
            <i className="fas fa-sign-in-alt d-none d-md-inline mr-md-0 mr-lg-2"></i>
            <span className="d-md-none d-lg-inline">Editar organización</span>
          </a>
          <a
            className="btn btn-rounded btn-solid mr-3 px-3 mt-2"
            href="/organizaciones/transacciones/<%= fundacion.id %>"
          >
            <i className="fas fa-sign-in-alt d-none d-md-inline mr-md-0 mr-lg-2"></i>
            <span className="d-md-none d-lg-inline">
              Administrar transacciones
            </span>
          </a>
        </nav>
      );
    } else return null;
  }

  return (
    <div className="Organizacion" style={{ width: "100%", minHeight: "92vh" }}>
      <main className="overflow-hidden bg-6">
        <header className="page header">
          <div className="content">
            <div className="row">
              <div className="col-md-9 col-xs-9">
                <h1 className="title nombreFundacion m-4">
                  {(state.organizacion && state.organizacion.nombre) ||
                    "Organización"}
                </h1>
              </div>
              <div className="col-md-3 col-xs-3">
                {renderEditarOrganizacion()}
              </div>
            </div>
          </div>
        </header>
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-xs-12" style={{ padding: "10px" }}>
                <div clas="row">
                  <div className="col-md-0 col-xs-2"></div>
                  <div className="card col-md-12 col-xs-8">
                    <img
                      id="fotoFundacion"
                      src={state.organizacion && state.organizacion.foto}
                      alt={
                        (state.organizacion && state.organizacion.nombre) ||
                        "Organización"
                      }
                      style={{ width: "100%", height: "100%" }}
                    />
                    <div>
                      <h5 className="tipoOrganizacion">
                        {state.tipoOrganizacion}
                      </h5>
                    </div>
                  </div>
                  <div className="col-md-0 col-xs-2"></div>
                </div>
              </div>
              <div className="col-md-9 col-xs-12">
                <div style={{ padding: "30px 80px" }}>
                  <h5
                    className="nombreFundacion"
                    style={{ display: "inline-block" }}
                  >
                    {" "}
                    {(state.organizacion && state.organizacion.nombre) ||
                      "Organización"}
                  </h5>{" "}
                  <span> - </span>
                  <span className="emailFundacion">
                    {" "}
                    {(state.organizacion && state.organizacion.email) ||
                      "Email"}
                  </span>
                  <p
                    className="semblanzaFundacion"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {(state.organizacion && state.organizacion.semblanza) ||
                      "Semblanza"}
                  </p>
                  <div
                    id="urlOficialContainer"
                    className="row"
                    style={{ marginTop: "30px" }}
                  >
                    <div className="col-md-1 col-xs-1">
                      <img src="/images/icons/ico_web.png" alt="Sitio web" />
                    </div>
                    <div className="col-md-10 col-xs-10">
                      <a
                        id="url_oficial"
                        href={
                          (state.organizacion &&
                            state.organizacion.url_oficial) ||
                          "# "
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p>Sitio oficial de la organización</p>
                      </a>
                    </div>
                  </div>
                  <div id="telefonoContainer" className="row">
                    <div className="col-md-1 col-xs-1">
                      <img src="/images/icons/ico_phone.png" alt="Teléfono" />
                    </div>
                    <div className="col-md-10 col-xs-10">
                      <p className="telefonoFundacion">
                        {(state.organizacion && state.organizacion.telefono) ||
                          "No hay telefono registrado"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "30px" }} id="seccionCausas">
              <div className="row">
                <div className="col-md-9 col-xs-9">
                  <h5 className="title" id="causasTitulo">
                    {state.organizacion &&
                      "Campañas lanzadas por " + state.organizacion.nombre}
                  </h5>
                </div>
                <div className="col-md-3 col-xs-3">
                  <nav className="navigation" style={{ backgroundColor: "rgba(0,0,0,0)" }}>
                    <a
                      className="btn btn-rounded btn-solid mr-3 px-3 mt-2"
                      href="/causas/nueva"
                    >
                      <i className="fas fa-sign-in-alt d-none d-md-inline mr-md-0 mr-lg-2"></i>
                      <span className="d-md-none d-lg-inline">
                        Crear nueva campaña
                      </span>
                    </a>
                  </nav>
                </div>
              </div>

              <div className="row" id="causasContainer">
                {state.causas &&
                  state.causas.map((causa) => (
                    <CardCausa causa={causa} key={causa._id} />
                  ))}
              </div>
            </div>
            <div className="row gap-y">
              <div className="col-6">
                <a
                  className="btn btn-3 d-flex flex-column align-items-center py-4 cmdAdmin"
                  id="hrefDocumentos"
                  href="/documentos/certificador/"
                >
                  <p className="mb-0">Documentos certificados</p>
                </a>
              </div>
            </div>
            <div id="graficasContainer" style={{ display: "none" }}>
              <div style={{ marginTop: "30px" }}>
                <h5 className="title">
                  Conoce la actividad de la institución:
                </h5>
              </div>
              <div className="row">
                <div
                  className="col-md-6 col-xs-6"
                  style={{ marginTop: "50px" }}
                >
                  <canvas
                    id="myChart1"
                    style={{ width: "100%", height: "400px" }}
                  ></canvas>
                </div>
                <div
                  className="col-md-6 col-xs-6"
                  style={{ marginTop: "50px" }}
                >
                  <canvas id="pie-chart" style={{ width: "100%" }}></canvas>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-12 col-xs-12"
                  style={{ marginTop: "50px", marginBottom: "50px" }}
                >
                  <canvas id="bar-chart-horizontal"></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Organizacion;
