import Api from "../util/api";
import React, { useState, useEffect } from "react";
import Util from "../util/util";
import ModalDocumentoEncontrado from "./modal/DocumentoEncontrado";
import swal from 'sweetalert2';
import useLoading from "../hooks/useLoading";

function BuscarTarjetaProduccion() {
  const { startLoading, stopLoading } = useLoading();
  const [state, setState] = useState({
    documentoEncontrado: null,
    showModalDocumentoEncontrado: false,
    showModalValidarDocumento: false,
    idGeneric: "",
  });

  function closeModals() {
    setState({
      ...state,
      showModalDocumentoEncontrado: false,
      showModalValidarDocumento: false,
    });
  }
  async function consultarDocumento() {
    if (state.idGeneric.length !== 64 && state.idGeneric.length !== 24) return;
    try {
      startLoading();
      var res = await Api.documentos.get(state.idGeneric);
      await Util.requestSuccess(res);
      res = await res.json();
      setState((prevState) => ({
        ...prevState,
        documentoEncontrado: res,
        showModalDocumentoEncontrado: true,
      }));
      console.log(res);
    } catch (e) {
      console.log(e);
      stopLoading();
      await swal.fire("¡Ups!", 'Error al consultar tarjeta de producción', "error");
    }
    stopLoading();
  }

  const handleChangeGenericId = (e) => {
    setState((prevState) => ({
      ...prevState,
      idGeneric: e.target.value,
    }));
  };
  useEffect(() => {
    var initialId = window.location.pathname.replace(
      "/app/documentos/validar/",
      ""
    );
    if (initialId.length > 0) {
      setState((prevState) => ({
        ...prevState,
        idGeneric: initialId,
      }));
      consultarDocumento();
    }
  }, [state.idGeneric]);
  return (
    <main className="overflow-hidden bg-6" style={{ width: "100%", minHeight: "92vh" }}>
      <header className="page header">

        <ModalDocumentoEncontrado
          show={state.showModalDocumentoEncontrado}
          onHide={closeModals}
          documentoEncontrado={state.documentoEncontrado}
        />
        <div className="content">
          <div className="row">
            <div className="col-md-9 col-xs-9">
              <h3 className="title nombreOrganizacion">Rastrear producto</h3>
            </div>
          </div>
        </div>
      </header>
      <div className="content">
        <div className="container">
          <p>Existen 2 formas para rastrear un producto: </p>
          <div className="row form">
            <div className="col-md-6 col-xs-12">
              <p>
                1 - Ingresa el número de lote del producto que
                quieres verificar.
              </p>
              <input
                type="text"
                placeholder="Folio de producción / Tx Hash / Número de lote"
                id="txtUID"
                onChange={handleChangeGenericId}
              />
              <button
                id="cmdConsultarUID"
                className="button"
                onClick={consultarDocumento}
              >
                Consultar documento
              </button>
            </div>
            <div className="col-md-6 col-xs-12">
              <p>
                2 - Si tu producto cuenta con un código QR, puedes leerlo por
                medio de la cámara web.
              </p>
              <button id="cmdAbrirCerrarCamaraWeb" className="button">
                Abrir cámara web
              </button>
              <video id="preview" style={{ width: "100%" }}></video>
            </div>
          </div>
          <div className="row"></div>
        </div>
      </div>
    </main>
  );
}

export default BuscarTarjetaProduccion;
