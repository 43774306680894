import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Util from "../../util/util";
import Api from "../../util/api";
import Settings from "../../config/settings";
import swal from 'sweetalert2';
import useLoading from "../../hooks/useLoading";

function DocumentoEncontrado(props) {
  const { startLoading, stopLoading } = useLoading();
  const [state, setState] = useState({
    showModalPdf: "none",
    srcUrlPdf: null,
    showModalAudio: "none",
    srcUrlAudio: null,
    showModalImg: "none",
    srcUrlImg: null,
    showModalText: "none",
    srcUrlText: null,
    scanerURL: null,

  });

  const audio = useRef(null);

  async function renderPdf() {
    console.log(props.documentoEncontrado);
    try {
      startLoading();
      var res = await Api.documentos.getUrl(props.documentoEncontrado._id);

      if (!await Util.requestSuccess(res)) {
        stopLoading();
        await swal.fire("¡Ups!", 'Error al consultar URL de archivo. ' + res.msg, "error");
      }

      res = await res.json();

      console.log(res);

      console.log(res.url, props.documentoEncontrado.mime);

      openModalDocument(res.url, props.documentoEncontrado.mime);
    } catch (e) {
      console.log(e);
      stopLoading();
      await swal.fire("¡Ups!", 'Error al consultar contenido de archivo', "error");
    }
    stopLoading();
  }

  function openModalDocument(srcUrl, mime) {
    if (!mime) mime = "";
    if (srcUrl) {
      if (mime.search("pdf") > 0 || srcUrl.search(".pdf") > 0) {
        setState((prevState) => ({
          ...prevState,
          showModalPdf: "block",
          srcUrlPdf: srcUrl,
        }));
      } else if (mime.search("audio") >= 0 || srcUrl.search(".mp3") > 0) {

        setState({
          ...state,
          showModalAudio: "block",
          srcUrlAudio: srcUrl,
        });
        audio.current.pause();
        audio.current.load();
        audio.current.play();
      } else if (
        mime.search("image") >= 0 ||
        srcUrl.search(".jpg") > 0 ||
        srcUrl.search(".png") > 0
      ) {
        setState({
          ...state,
          showModalImg: "block",
          srcUrlImg: srcUrl,
        });
      } else if (mime === "Unknown" || mime.search("xml") >= 0) {
        setState({
          ...state,
          showModalText: "block",
          srcUrlText: srcUrl,
        });
      } else {
        window.location.href = srcUrl;
      }
    }
  }

  useEffect(() => {
    if (!props.documentoEncontrado) return;
    var scanerURL;
    if (props.documentoEncontrado.blockchain === "Polygon") {
      scanerURL = Settings.url.etherscan_explorer;
    } else scanerURL = Settings.url.lacchain_explorer;
    setState((prevState) => ({
      ...prevState,
      scanerURL,
    }));
  }, [props.documentoEncontrado]);


  return (
    <div className="DocumentoEncontrado">
      <Modal {...props} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>¡Lo encontramos!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="documentoEncontrado">
            <p id="subtitleSearch">
              {("Archivo certificado por " +
                props.documentoEncontrado?.emisor?.nombre) ||
                "Archivo encontrado"}
            </p>
            <div className="row">
              <div className="col-md-6 col-xs-6 col-sm-6" >
                <p className="bold">Fecha de certificación</p>
              </div>
              <div className="col-md-6 col-xs-6 col-sm-6">
                <p>{(props.documentoEncontrado && Util.formatDateShort(props.documentoEncontrado.fecha_certificacion)) || ""}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-6" >
                <p className="bold">Vigencia</p>
              </div>
              <div className="col-md-6 col-xs-6">
                <p>{(props.documentoEncontrado && Util.formatDateShort(props.documentoEncontrado.vigencia)) || "N/A"}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-6" >
                <p className="bold">Nombre</p>
              </div>
              <div className="col-md-6 col-xs-6">
                <p>{(props.documentoEncontrado && props.documentoEncontrado.nombre) || ""}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-6" >
                <p className="bold">Hash de Archivo</p>
              </div>
              <div className="col-md-6 col-xs-6">
                <p>{(props.documentoEncontrado && props.documentoEncontrado.fileHash.substring(0, 35) + "...")}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-6" >
                <a href=" #" onClick={renderPdf}>
                  <button className="button">
                    Ver archivo
                  </button>
                </a>
              </div>
              <div className="col-md-6 col-xs-6">
                <a target="_blank" rel="noreferrer" href={props.documentoEncontrado && state.scanerURL + '/tx/' + props.documentoEncontrado.txHash}>
                  <button className="button">
                    Ver en Blockchain
                  </button>
                </a>
              </div>
            </div>

            <div id="pdfViewerContainer" style={{ display: state.showModalPdf }}>
              <object
                data={
                  state.srcUrlPdf ||
                  "https://s3.amazonaws.com/pesoxpesobucket/documentos/certificados/5cfb0b32dac5810049e2ef18/a44.pdf"
                }
                id="pdfViewer"
                type="application/pdf"
                width="100%"
                height="500px"
              >
                <p>
                  <b>Error al mostrar archivo</b>: Este navegador no soporta
                  PDFs. Por favor descarga el PDF para verlo:
                  <a href={
                    state.srcUrlPdf ||
                    "https://s3.amazonaws.com/pesoxpesobucket/documentos/certificados/5cfb0b32dac5810049e2ef18/a44.pdf"
                  } id="pdfDownload">
                    Descargar PDF
                  </a>
                  .
                </p>
              </object>
            </div>
            <div id="audioPlayerContainer" style={{ display: state.showModalAudio }}>
              <audio
                controls
                autoPlay
                style={{ width: "100%" }}
                id="audioPlayer"
                ref={audio}
              >
                <source src={state.srcUrlAudio} id="mp3Src" type="audio/mpeg" />
                Tu navegador no soporta elementos de audio.
              </audio>
            </div>
            <div id="imgViewerContainer" style={{ display: state.showModalImg }}>
              <img
                id="imgCert"
                style={{ width: "100%" }}
                alt="Imágen certificada"
                src={state.srcUrlImg}
              />
            </div>
            <div id="textViewerContainer" style={{ display: state.showModalText }}>
              <textarea
                id="textCert"
                style={{ width: "100%", height: "500px" }}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}

export default DocumentoEncontrado;