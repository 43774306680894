//import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Api from "../../util/api";
import Util from "../../util/util";
import Settings from "../../config/settings";
import swal from 'sweetalert2';
import useLoading from "../../hooks/useLoading";

function ComprarCertificacionDocumentos(props) {
    //const history = useHistory();
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        user: { saldo_certificar_docs: 0 },
        documentos_a_certificar: null,
        estados_documentos_a_certificar: null,
        loading: false,
        error: null,
        deviceSessionId: null,
        openPayToken: {
            card_number: null,
            holder_name: null,
            expiration_year: null,
            expiration_month: null,
            cvv2: null
        }
    });

    useEffect(() => {
        window.OpenPay.setId(Settings.openpay.id);
        window.OpenPay.setApiKey(Settings.openpay.public_key);

        if ("<%= env %>" == "pro") {
            window.OpenPay.setSandboxMode(false);
        } else {
            window.OpenPay.setSandboxMode(true);
        }

        setState((prevState) => ({
            ...prevState,
            deviceSessionId: window.OpenPay.deviceData.setup()
        }));
        //apiConsultarSuscripcionActiva();
    }, []);

    const handleChangeOpenPay = (e) => {
        var { id, value } = e.target;
        if (id === "card_number") {
            value = value.replace(/[^\d]/, '');
        }
        setState((prevState) => ({
            ...prevState,
            openPayToken: {
                ...prevState.openPayToken,
                [id]: value,
            },
        }));
    };

    const pagar = () => {
        startLoading();
        console.log(state.openPayToken);
        window.OpenPay.token.create(state.openPayToken, onSuccess, onError);
    }

    async function onSuccess(e) {
        try {
            const cardToken = e.data.id;
            console.log(cardToken);
            console.log({
                cardToken,
                deviceSessionId: state.deviceSessionId,
                paqueteReferencia: props.paquete,
                tarjeta: state.openPayToken.card_number,
            });
            var res = await Api.pagos.nuevo({
                cardToken,
                deviceSessionId: state.deviceSessionId,
                paqueteReferencia: props.paquete,
                tarjeta: state.openPayToken.card_number,
            });
            if (!await Util.requestSuccess(res)) {
                res = await res.json();
                stopLoading();
                await swal.fire("¡Ups!", "Error al registrar suscripción. "+res.msg, "error");
                console.log(res);
                
                props.onHide();
                return;
            }
            await swal.fire({ icon: 'success', html: "Suscripción registrada con éxito" });
        } catch (e) {
            console.log(e);
            stopLoading();
            await swal.fire("¡Ups!", "Error al registrar suscripción", "error");
        }
        
    }

    async function onError(e) {
        stopLoading();
        await swal.fire("¡Ups!", "Error al registrar datos de pago", "error");
        console.log(e);
        
    }

    return (
        <div className="ComprarCertificacionDocumentos">
            <Modal {...props} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Realizar pago con tarjeta de crédito o débito</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="payment-form" action="/action_page.php">
                        <div className="row">
                            <div className="col-md-12">
                                <p>Monto a pagar mensualmente</p>
                                <h1 id="lblMonto">$ {props.precio} MXN</h1>
                                <div className="row my-5">
                                    <div className="col-3">
                                        <h6>Tarjetas de crédito</h6>
                                        <img src="/images/payment/cards1.png" alt="tarjetas aceptadas" />
                                    </div>
                                    <div className="col-9">
                                        <h6>Tarjetas de débito</h6>
                                        <img src="/images/payment/cards2.png" alt="tarjetas aceptadas" />
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-6 col-xs-6">
                                        <label>Nombre en tarjeta</label>
                                        <input type="text" id="holder_name" name="cardname"
                                            placeholder="Juan Hernandez Hernandez" onChange={handleChangeOpenPay} />
                                    </div>
                                    <div className="col-md-6 col-xs-6">
                                        <label>Número de tarjeta</label>
                                        <input type="text" autoComplete="off"
                                            id="card_number" name="cardnumber"
                                            placeholder="Como aparece en la tarjeta" onChange={handleChangeOpenPay} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-xs-3">
                                        <label>Mes de expiración</label>
                                        <input type="text" id="expiration_month" name="expmonth"
                                            placeholder="01" onChange={handleChangeOpenPay} />
                                    </div>
                                    <div className="col-md-3 col-xs-3">
                                        <label>Año de expiración</label>
                                        <input type="text" id="expiration_year" name="expyear"
                                            placeholder="22" max="2" onChange={handleChangeOpenPay} />
                                    </div>
                                    <div className="col-md-3 col-xs-3">
                                        <label>CVV</label>
                                        <input type="text" id="cvv2" name="cvv" placeholder="123"
                                            max="2" onChange={handleChangeOpenPay} />
                                    </div>
                                    <div className="col-md-3 col-xs-3 pt-4">
                                        <img src="/images/payment/cvv.png" alt="cvv" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="color-2 mb-4"><input type="checkbox"
                            name="aceptoPoliticaPrivacidad" id="aceptoPoliticaPrivacidad" />Acepto
                            los <a href="/terminos-condiciones" target="_blank"
                                className="accent bold">Terminos y condiciones</a> y
                            la <a href="/politica-privacidad" target="_blank"
                                className="accent bold">Política
                                de privacidad</a></p>
                        <div className="openpay">
                            <div className="row">
                                <div className="col-4">
                                    <div>Transacciones realizadas vía:</div>
                                    <img src="/images/payment/openpay.png" alt="openpay" />
                                </div>
                                <div className="col-4">
                                    <div className="row">
                                        <div className="col-2">
                                            <img src="/images/payment/security.png" alt="openpay security" />
                                        </div>
                                        <div className="col-10">

                                            <span>Tus pagos se realizan de forma segura con
                                                encriptación de 256 bits</span>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={pagar}>
                        Pagar
                    </Button>
                    <Button variant="secondary" onClick={props.onHide}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ComprarCertificacionDocumentos;
