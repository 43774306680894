import React, { useEffect, useState } from "react";
import CardCausa from "./CardCausa";
import Api from "../util/api";
import Util from "../util/util";
function ListaCausas(props) {
  const [state, setState] = useState({
    causas: null,
    loading: true,
  });
  useEffect(() => {
    async function fetchData() {
      try {
        props.onLoading(true);
        var res = await Api.causas.list();
        var causas = (await res.json()).causas;
        console.log(causas);
        if (await Util.requestSuccess(res)) {
          setState({
            causas,
          });
        }
        console.log(res);
      } catch (e) {
        console.log(e);
      }
      props.onLoading(false);
    }
    fetchData();
  }, []);
  return (
    <main className="overflow-hidden bg-6" style={{ width: "100%", minHeight: "92vh" }}>
      <header className="page header">
        <div className="content">
          <div className="row">
            <div className="col-md-9 col-xs-9">
              <h1 className="title">Causas</h1>
            </div>
            <div className="col-md-3 col-xs-3">
              <a href="../causas/nueva" style={{ display: "none" }}>
                <h4 className="title">Crear nueva causa</h4>
              </a>
            </div>
          </div>
        </div>
      </header>
      <div className="content">
        <div className="container">
          <div className="row" id="causasContainer">
            {state.causas &&
              state.causas.map((causa) => (
                <CardCausa causa={causa} key={causa._id} />
              ))}
          </div>
        </div>
      </div>
    </main>
  );
}

export default ListaCausas;
