import React, { useEffect, useState } from "react";
import Api from "../util/api";
import Util from "../util/util";
import CardTarjetaProduccion from "./CardTarjetaProduccion";
import Settings from "../config/settings";
import swal from 'sweetalert2';
import QRCode from "react-qr-code";
import * as Icon from 'react-bootstrap-icons';
import useLoading from "../hooks/useLoading";


function TarjetaProduccion() {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        tarjeta_produccion: null,
        tarjeta_produccion_id: "",
        etapas_produccion: null,
        loading: true,
        admin: false,
        tipoOrganizacion: null,
    });

    useEffect(() => {

        async function init() {
            if (state.tarjeta_produccion_id.length === 0) {
                var initialId = window.location.pathname.replace(
                    "/app/tarjetas-suministro/",
                    ""
                );
                if (initialId.length > 0) {
                    consultarTarjeta(initialId.replace("/", ""));
                } else {
                    await swal.fire("¡Cuidado!", "¡Tarjeta de producción no encontrada", "error");
                }
            }
        }

        init();

    }, [state.tarjeta_produccion_id, state.tarjeta_produccion]);

    async function consultarTarjeta(tarjeta_produccion_id) {
        startLoading();
        try {
            var res = await Api.tarjetas_produccion.get(tarjeta_produccion_id);
            await Util.requestSuccess(res);
            res = await res.json();
            console.log(res.tarjeta);
            var tarjeta_produccion = res.tarjeta;
            setState((prevState) => ({
                ...prevState,
                admin: res.admin,
                tarjeta_produccion,
                tarjeta_produccion_id: tarjeta_produccion_id,
            }));
            if (tarjeta_produccion.estado !== "Activo") {
                stopLoading();
                await swal.fire("¡Cuidado!", "¡El estado de este producto es " + tarjeta_produccion.estado + "! ", "warning");
            }

            consultarTrazabilidadTarjeta(tarjeta_produccion_id);
        } catch (e) {
            console.log(e);
        }
        stopLoading();
    }

    async function openModalReportarIncidente() {
        try {
            var nuevoEstado = await swal.fire({
                title: 'Reportar incidente',
                input: 'select',
                cancelButtonText: "Cancelar",
                inputOptions: {
                    'Contaminado': 'Contaminado',
                    'Robado': 'Robado',
                    'Vencido': 'Vencido'
                },
                inputPlaceholder: 'Nuevo estado de producto',
                showCancelButton: true,
                inputValidator: function (value) {
                    return new Promise(function (resolve, reject) {
                        if (value !== '') {
                            resolve();
                        } else {
                            resolve('Es necesario que selecciones el estado del producto');
                        }
                    });
                }
            });
            console.log(nuevoEstado);
            if (!nuevoEstado.isConfirmed) {
                return;
            }
            var confirm = await swal.fire({
                title: '¿Estás seguro?',
                text: "Esta acción no se puede revertir",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                icon: "warning",
                buttons: {
                    cancel: "Cancelar",
                    ok: "Eliminar",
                },
                dangerMode: true,
            });
            if (!confirm.isConfirmed) {
                return;
            }
            startLoading();
            const estado = nuevoEstado.value;
            var res = await Api.tarjetas_produccion.actualizarEstado(state.tarjeta_produccion_id, { estado });
            if (!await Util.requestSuccess(res)) {
                stopLoading();
                await swal.fire("Ups!", "Error al reportar incidente", "error");
                return;
            }

            setState((prevState) => ({
                ...prevState,
                tarjeta_produccion: {
                    ...prevState.tarjeta_produccion,
                    estado
                }
            }));
            stopLoading();
            await swal.fire("¡Listo!", 'Cambiaste el estado del producto a: ' + estado, "success");
        } catch (e) {
            console.log(e);
            stopLoading();
            await swal.fire("Ups!", "Error al reportar incidente", "error");
        }
        stopLoading();

    }

    async function consultarTrazabilidadTarjeta(tarjeta_produccion_id) {
        startLoading();
        try {
            console.log("Trazabilidad");
            var res = await Api.tarjetas_produccion.getTrazabilidad(tarjeta_produccion_id);
            await Util.requestSuccess(res);
            res = await res.json();
            console.log(res);

            setState((prevState) => ({
                ...prevState,
                etapas_produccion: res.etapas,
            }));

        } catch (e) {
            console.log(e);
        }
        stopLoading();
    }

    function renderEditarTarjetaProduccion(props) {
        console.log(state.admin);
        //if (state.admin === "FUND") {
        console.log(true);
        return (
            <nav className="navigation" style={{ backgroundColor: "rgba(0,0,0,0)" }}>
                <a
                    id="cmdEditarOrganizacion"
                    className="btn btn-rounded btn-solid mr-3 px-3 mt-2"
                    href={"# "} onClick={openModalReportarIncidente}
                >
                    <i className="fas fa-sign-in-alt d-none d-md-inline mr-md-0 mr-lg-2"></i>
                    <span className="d-md-none d-lg-inline">Reportar incidente</span>
                </a>
            </nav>
        );
        //} else return null;
    }

    function renderEstadoTarjeta() {

        if (!state.tarjeta_produccion) {
            return (null);
        }
        if (state.tarjeta_produccion.estado === "Activo") {
            return (
                <span className="emailFundacion text-success">
                    {" "}
                    {state.tarjeta_produccion.estado}
                </span>
            );
        } else {

            return (
                <span className="emailFundacion text-danger">
                    {" "}
                    {state.tarjeta_produccion.estado}
                </span>
            );
        }

    }

    var scanerURL;
    if (state.tarjeta_produccion) {
        if (state.tarjeta_produccion.blockchain === "Lacchain") {
            scanerURL = Settings.url.lacchain_explorer;
        } else scanerURL = Settings.url.etherscan_explorer;
    }


    return (
        <div className="Organizacion" style={{ width: "100%", minHeight: "92vh" }}>
            <main className="overflow-hidden bg-6">
                <header className="page header">
                    <div className="content">
                        <div className="row">
                            <div className="col-md-9 col-xs-9">
                                <h1 className="title nombreFundacion m-4">
                                    {(state.tarjeta_produccion && state.tarjeta_produccion.nombre) ||
                                        "Tarjeta de producción"}
                                </h1>
                            </div>
                            <div className="col-md-3 col-xs-3">
                                {renderEditarTarjetaProduccion()}
                            </div>
                        </div>
                    </div>
                </header>
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-12" style={{ padding: "10px" }}>
                                <div clas="row">
                                    <div className="col-md-0 col-xs-2"></div>
                                    <div className="card col-md-12 col-xs-8">
                                        <img
                                            id="fotoFundacion"
                                            src={state.tarjeta_produccion && state.tarjeta_produccion.producto && state.tarjeta_produccion.producto.foto}
                                            alt={
                                                (state.tarjeta_produccion && state.tarjeta_produccion.nombre) ||
                                                "Producto"
                                            }
                                            hidden={state.tarjeta_produccion == null}
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                        <div>
                                            <h5 className="tipoOrganizacion">
                                                {state.tipoOrganizacion}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-md-0 col-xs-2"></div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div style={{ padding: "30px 50px" }}>
                                    <h5
                                        className="nombreFundacion"
                                        style={{ display: "inline-block" }}
                                    >
                                        {" "}
                                        {(state.tarjeta_produccion && state.tarjeta_produccion.producto.nombre) ||
                                            "Planta de producción"}
                                    </h5>{" "}
                                    <span> - </span>
                                    {renderEstadoTarjeta()}
                                    <p
                                        className="semblanzaFundacion"
                                        style={{ whiteSpace: "pre-wrap" }}
                                    >

                                    </p>
                                    <div className="row" style={{ marginTop: "20px" }}>
                                        <div className="col-md-1 col-2">
                                            <Icon.Calendar4Event size="25" />
                                        </div>
                                        <div className="col-md-10 col-10">
                                            <p>Elaboración:{(state.tarjeta_produccion && " " + Util.formatDateShort(state.tarjeta_produccion.fecha_registro)) || " N/A"}</p>
                                        </div>
                                    </div>
                                    <div className="row" hidden={state.tarjeta_produccion == null || state.tarjeta_produccion.fecha_consumo_preferente == null}>
                                        <div className="col-md-1 col-2">
                                            <Icon.Calendar4Event size="25" />
                                        </div>
                                        <div className="col-md-10 col-10">
                                            <p>Consumo preferente:{(state.tarjeta_produccion && " " + Util.formatDateShort(state.tarjeta_produccion.fecha_consumo_preferente)) || " N/A"}</p>
                                        </div>
                                    </div>
                                    <div className="row" hidden={state.tarjeta_produccion == null || state.tarjeta_produccion.fecha_caducidad == null}>
                                        <div className="col-md-1 col-2">
                                            <Icon.Calendar4Event size="25" />
                                        </div>
                                        <div className="col-md-10 col-10">
                                            <p>Caducidad:{(state.tarjeta_produccion && " " + Util.formatDateShort(state.tarjeta_produccion.fecha_caducidad)) || " N/A"}</p>
                                        </div>
                                    </div>
                                    <div className="row" hidden={!state.tarjeta_produccion?.planta_produccion?.nombre}>
                                        <div className="col-md-1 col-2">
                                            <Icon.Building size="25" />
                                        </div>
                                        <div className="col-md-10 col-10">
                                            <p className="telefonoFundacion">
                                                {(state.tarjeta_produccion?.planta_produccion?.nombre) ||
                                                    ""}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row" hidden={!state.tarjeta_produccion?.txHash}>
                                        <div className="col-md-1 col-2">
                                            <Icon.Bricks size="25" />
                                        </div>
                                        <div className="col-md-10 col-10">
                                            <a
                                                href={
                                                    (
                                                        scanerURL + "/tx/" + state.tarjeta_produccion?.txHash) ||
                                                    "# "
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <p>Ver en Blockchain</p>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-1 col-2">
                                            <Icon.Check2Square size="25" />
                                            <span className="glyphicon glyphicon-search" aria-hidden="true"></span>
                                        </div>
                                        <div className="col-md-10 col-10">
                                            <p className="telefonoFundacion">
                                                {(state.tarjeta_produccion && state.tarjeta_produccion.data) ||
                                                    "No hay información registrada"}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-1 col-2">
                                            <Icon.GeoAlt size="25" />
                                        </div>
                                        <div className="col-md-10 col-10">
                                            <p className="telefonoFundacion">
                                                {(state.tarjeta_produccion && state.tarjeta_produccion.planta_produccion.direccion) ||
                                                    "No hay dirección registrada"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-12">
                                <div className="row">
                                    <div className="col-md-0 col-1">
                                    </div>
                                    <div className="col-md-12 col-10">
                                        <QRCode
                                            value={(state.tarjeta_produccion && Settings.url.web + "/app/tarjetas-suministro/" + state.tarjeta_produccion._id) || ""}
                                            hidden={state.tarjeta_produccion == null} title="Código QR del lote/tarjeta de producción" />
                                    </div>
                                    <div className="col-md-0 col-1">
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div style={{ marginTop: "30px" }} id="seccionCausas">
                            <div className="row">
                                <div className="col-md-9 col-xs-9">
                                    <h3 className="title" id="tarjetasTitulo">
                                        ¿Cómo se hizo?
                                    </h3>
                                </div>
                            </div>

                            <div className="row" id="tarjetasContainer">
                                {state.etapas_produccion &&
                                    state.etapas_produccion.map((etapa) => (
                                        <CardTarjetaProduccion tarjeta={etapa} key={etapa._id} />
                                    ))}
                            </div>
                        </div>

                    </div>
                </div>
            </main >
        </div >
    );
}

export default TarjetaProduccion;
