import React, { useEffect, useState } from "react";
import Api from "../util/api";
import Util from "../util/util";
import CtlSeguimientoEgreso from "./CtlSeguimientoEgreso";
import CtlSeguimientoIngreso from "./CtlSeguimientoIngreso";
import CtlSeguimientoDonacionItem from "./CtlSeguimientoDonacionItem";
import useLoading from "../hooks/useLoading";
function SeguimientoDonativos(props) {
  const { startLoading, stopLoading } = useLoading();
  const [state, setState] = useState({
    entidad: null,
    entidadTipo: null,
    entidadId: "",
    ingresos: null,
    egresos: null,
    loading: true,
    admin: false,
    montoTotalEgresos: null,
    montoTotalIngresos: null,
    containerIngresosDisplay: "block",
    containerEgresosDisplay: "block",
    lineaSeguimiento: JSON.parse(sessionStorage.getItem("lineaSeguimiento")),
  });

  useEffect(() => {
    async function consultarIngresos() {
      if (!state.entidad) return;
      startLoading();
      try {
        var res = await Api.facturas.listar_ingresos(
          state.entidad._id,
          state.entidadTipo
        );
        await Util.requestSuccess(res);
        res = await res.json();
        console.log(res);
        var ingresos = res.facturas;
        var montoTotalIngresos = 0;
        for (var i = 0; i < ingresos.length; i++) {
          montoTotalIngresos += consultarMontoFactura(ingresos[i]);
        }
        setState((prevState) => ({
          ...prevState,
          ingresos,
          montoTotalIngresos,
        }));
      } catch (e) { }
      /*if (state.montoTotalEgresos != null) */stopLoading();
    }

    async function consultarEgresos() {
      if (!state.entidad) return;
      startLoading();
      try {
        var res = await Api.facturas.listar_egresos(
          state.entidad._id,
          state.entidadTipo
        );
        await Util.requestSuccess(res);
        res = await res.json();
        console.log(res);
        var egresos = res.facturas;
        var montoTotalEgresos = 0;
        for (var i = 0; i < egresos.length; i++) {
          montoTotalEgresos += consultarMontoFactura(egresos[i]);
        }
        setState((prevState) => ({
          ...prevState,
          egresos,
          montoTotalEgresos,
        }));
      } catch (e) { }
      /*if (state.montoTotalIngresos != null) */stopLoading();
    }

    if (state.entidadId.length === 0) {
      var initialId = window.location.pathname.replace(
        "/app/transacciones",
        ""
      );
      if (initialId.length > 0) {
        consultarEntidad(initialId.replace("/", ""));
      }
    } else {
      consultarIngresos();
      consultarEgresos();
    }
  }, [state.entidadId, state.entidad, state.entidadTipo]);

  function consultarMontoFactura(factura) {
    //alert(factura.monto + " " + factura.monto_especie);
    if (factura.monto > 0) {
      return factura.monto;
    } else return factura.monto_especie;
  }

  async function consultarEntidad(entidadId) {
    try {
      startLoading();
      var res = await Api.buscar.buscar_entidad(entidadId);
      await Util.requestSuccess(res);
      res = await res.json();
      console.log(res);
      setState((prevState) => ({
        ...prevState,
        entidad: res.obj,
        entidadTipo: res.type,
        entidadId,
      }));
    } catch (e) {
      console.log(e);
    }
  }

  /*function mostrarEsconderLista(id, idArrow) {
    if (state[id + "display"] == "none") {
      setState((prevState) => ({
        ...prevState,
        [id + "display"]: "block",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [id + "display"]: "none",
      }));
    }
  }*/

  return (
    <div className="SeguimientoDonativos" style={{ minHeight: "92vh" }}>
      <main className="overflow-hidden bg-6">
        <header className="page header">
          <div className="content"></div>
          <div className="content">
            <div className="row">
              <div
                className="nav-steps"
                style={{ width: "100%" }}
                id="lineaSeguimiento"
              >
                {state.lineaSeguimiento &&
                  state.lineaSeguimiento.map((entidad, index) => (
                    <CtlSeguimientoDonacionItem
                      entidad={entidad}
                      key={index}
                      onLoading={props.onLoading}
                      longitudLineaSeguimiento={state.lineaSeguimiento.length}
                    />
                  ))}
              </div>
            </div>
            <div className="row" style={{ marginTop: "50px" }}>
              <div className="col-md-9">
                <h1 className="title" id="lblTitleTransacciones">
                  {(state.entidad &&
                    "Transacciones de " + state.entidad.nombre) ||
                    "Transacciones"}
                </h1>
              </div>
            </div>
          </div>
        </header>
        <div className="content" style={{ padding: 0 }}>
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-xs-12" style={{ padding: "10px" }}>
                <div clas="row">
                  <div className="col-md-0 col-xs-2"></div>
                  <div className="card col-md-12 col-xs-8">
                    <img
                      id="fotoUsuario"
                      src={
                        (state.entidad && state.entidad.foto) ||
                        "/admin/user.png"
                      }
                      alt={
                        (state.entidad && state.entidad.nombre) ||
                        "Foto de perfil"
                      }
                      style={{ width: "100%", height: "100%" }}
                    />
                    <div>
                      <b>
                        <h4 className="nombreUsuario">
                          {(state.entidad && state.entidad.nombre) || ""}
                          {(state.entidad && " " + state.entidad.apellidos) ||
                            ""}
                        </h4>
                      </b>
                    </div>
                  </div>
                  <div className="col-md-0 col-xs-2"></div>
                </div>
              </div>
              <div className="col-md-9 " style={{ padding: "30px 80px" }}>
                <h4>Correo electrónico:</h4>
                <p className="emailUsuario">
                  {(state.entidad && state.entidad.email) || ""}
                </p>
                <div id="semblanzaContainer">
                  <h4>Semblanza del usuario:</h4>
                  <p id="semblanzaUsuario">
                    {(state.entidad && state.entidad.semblanza) || ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-xs-6">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-xs-6">
                      <h3 id="lblIngresos">
                        {(state.ingresos &&
                          state.ingresos.length +
                          " ingresos encontrados por un valor de $" +
                          Util.numberWithCommas(
                            state.montoTotalIngresos.toFixed(2)
                          ) +
                          "MXN") ||
                          "Ingresos"}
                      </h3>
                    </div>
                    <div className="col-md-6 col-xs-6">
                      <li
                        className="up"
                        id="arrowIngresos"
                      ></li>
                    </div>
                  </div>
                </div>
                <div className="container-fluid" id="containerIngresos">
                  <div className="row">
                    <div className="col-md-2 col-xs-2">Fecha</div>
                    <div className="col-md-2 col-xs-2">Monto</div>
                    <div className="col-md-3 col-xs-3">Recibido de</div>
                    <div className="col-md-2 col-xs-2">Categoría</div>
                  </div>
                  {state.ingresos &&
                    state.ingresos.map((ingreso) => (
                      <CtlSeguimientoIngreso
                        transaccion={ingreso}
                        entidad={state.entidad}
                        onLoading={props.onLoading}
                        key={ingreso._id}
                      />
                    ))}
                </div>
              </div>
              <div className="col-md-6 col-xs-6">
                <div className="row">
                  <div className="col-md-6 col-xs-6">
                    <h3 id="lblEgresos">
                      {(state.egresos &&
                        state.egresos.length +
                        " egresos encontrados por un valor de $" +
                        Util.numberWithCommas(
                          state.montoTotalEgresos.toFixed(2)
                        ) +
                        "MXN") ||
                        "Egresos"}
                    </h3>
                  </div>
                  <div className="col-md-6 col-xs-6">
                    <li
                      className="up"
                      id="arrowEgresos"
                    ></li>
                  </div>
                </div>
                <div className="container-fluid" id="containerEgresos">
                  <div className="row">
                    <div className="col-md-2 col-xs-2">Fecha</div>
                    <div className="col-md-2 col-xs-2">Monto</div>
                    <div className="col-md-3 col-xs-3">Pagado a</div>
                    <div className="col-md-2 col-xs-2">Categoría</div>
                  </div>
                  {state.egresos &&
                    state.egresos.map((egreso) => (
                      <CtlSeguimientoEgreso
                        transaccion={egreso}
                        entidad={state.entidad}
                        onLoading={props.onLoading}
                        key={egreso._id}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default SeguimientoDonativos;
