import React from "react";
import Util from "../util/util";

function ItinerarioItem(props) {
  function compararFechas() {
    var today = new Date();
    var dateInicioTemp = new Date(props.etapa.fecha_inicio);
    var dateFinTemp = new Date(props.etapa.fecha_fin);
    if (today < dateInicioTemp) {
      return "";
    } else if (today > dateInicioTemp && today < dateFinTemp) {
      return "icon-current icon-node";
    } else {
      return "icon-current icon-node";
    }
  }
  return (
    <li className="step">
      <div className={"icon-con " + compararFechas()}></div>
      <p>{props.etapa.descripcion}</p>
      <p>
        {Util.formatDateShort(props.etapa.fecha_inicio) +
          " al " +
          Util.formatDateShort(props.etapa.fecha_fin)}
      </p>
    </li>
  );
}

export default ItinerarioItem;
