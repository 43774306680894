import React from "react";
function CardCausa(props) {
  return (
    <div className="CardCausa col-md-3 col-xs-6" style={{ padding: "10px" }}>
      <a href={"/app/causas/" + props.causa._id}>
        <div className="card">
          <img
            src={props.causa.fotos}
            alt={props.causa.nombre}
            style={{ width: "100%" }}
          />
          <div>
            <h5>{props.causa.nombre}</h5>
          </div>
        </div>
      </a>
    </div>
  );
}

export default CardCausa;
