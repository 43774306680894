import React, { useEffect, useState } from "react";
import Api from "../util/api";
import Util from "../util/util";
import CardTarjetaProduccion from "./CardTarjetaProduccion";
import ModalNuevaTarjetaProduccion from "./modal/NuevaTarjetaProduccion";
import useLoading from "../hooks/useLoading";

function PlantaProduccion(props) {
  const { startLoading, stopLoading } = useLoading();
  const [state, setState] = useState({
    planta_produccion: null,
    showModalNuevaTarjeta: false,
    planta_produccion_id: "",
    loading: true,
    admin: false,
    tipoOrganizacion: null,
  });

  function closeModal() {
    setState({
      ...state,
      showModalNuevaTarjeta: false,
    });
  }

  function openModalNuevaTarjeta() {
    setState({
      ...state,
      showModalNuevaTarjeta: true,
    });
  }

  useEffect(() => {
    async function renderTarjetas(planta_produccion_id) {
      startLoading();
      try {
        var res = await Api.tarjetas_produccion.list("?planta_produccion=" + planta_produccion_id);
        await Util.requestSuccess(res);
        res = await res.json();
        console.log(res);
        setState((prevState) => ({
          ...prevState,
          tarjetas: res.tarjetas,
        }));
      } catch (e) {

      }
      stopLoading();
    }

    if (state.planta_produccion_id.length === 0) {
      var initialId = window.location.pathname.replace(
        "/app/plantas-suministro/",
        ""
      );
      if (initialId.length > 0) {
        consultarOrganizacion(initialId.replace("/", ""));
        renderTarjetas(initialId.replace("/", ""));
      }
    }
  }, [state.planta_produccion_id, state.planta_produccion]);

  async function consultarOrganizacion(planta_produccion_id) {
    try {
      startLoading();
      var res = await Api.plantas_produccion.get(planta_produccion_id);
      await Util.requestSuccess(res);
      res = await res.json();
      console.log(res);
      setState((prevState) => ({
        ...prevState,
        admin: res.admin,
        planta_produccion: res.plantaProduccion,
        planta_produccion_id: planta_produccion_id,
      }));
    } catch (e) {
      console.log(e);
    }
    stopLoading();
  }

  function renderEditarPlantaProduccion(props) {
    console.log(state.admin);
    if (state.admin === "FUND") {
      console.log(true);
      return (
        <nav className="navigation" style={{ backgroundColor: "rgba(0,0,0,0)" }}>
          <a
            id="cmdEditarOrganizacion"
            className="btn btn-rounded btn-solid mr-3 px-3 mt-2"
            href={
              (state.planta_produccion &&
                "/app/planta_producciones/editar/" + state.planta_produccion._id) ||
              "# "
            }
          >
            <i className="fas fa-sign-in-alt d-none d-md-inline mr-md-0 mr-lg-2"></i>
            <span className="d-md-none d-lg-inline">Editar planta de producción</span>
          </a>
          <a
            className="btn btn-rounded btn-solid mr-3 px-3 mt-2"
            href="/planta_producciones/transacciones/<%= fundacion.id %>"
          >
            <i className="fas fa-sign-in-alt d-none d-md-inline mr-md-0 mr-lg-2"></i>
            <span className="d-md-none d-lg-inline">
              Administrar transacciones
            </span>
          </a>
        </nav>
      );
    } else return null;
  }

  return (
    <div className="Organizacion" style={{ width: "100%", minHeight: "92vh" }}>
      <main className="overflow-hidden bg-6">
        <header className="page header">
          <ModalNuevaTarjetaProduccion
            show={state.showModalNuevaTarjeta}
            onHide={closeModal}
            onLoading={props.onLoading}
            planta_produccion={state.planta_produccion}
          />
          <div className="content">
            <div className="row">
              <div className="col-md-9 col-xs-9">
                <h1 className="title nombreFundacion m-4">
                  {(state.planta_produccion && state.planta_produccion.nombre) ||
                    "Planta de producción"}
                </h1>
              </div>
              <div className="col-md-3 col-xs-3">
                {renderEditarPlantaProduccion()}
              </div>
            </div>
          </div>
        </header>
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-xs-12" style={{ padding: "10px" }}>
                <div clas="row">
                  <div className="col-md-0 col-xs-2"></div>
                  <div className="card col-md-12 col-xs-8">
                    <img
                      id="fotoFundacion"
                      src={state.planta_produccion && state.planta_produccion.propietario && state.planta_produccion.propietario.foto}
                      alt={
                        (state.planta_produccion && state.planta_produccion.nombre) ||
                        "Planta de producción"
                      }
                      style={{ width: "100%", height: "100%" }}
                    />
                    <div>
                      <h5 className="tipoOrganizacion">
                        {state.tipoOrganizacion}
                      </h5>
                    </div>
                  </div>
                  <div className="col-md-0 col-xs-2"></div>
                </div>
              </div>
              <div className="col-md-9 col-xs-12">
                <div style={{ padding: "30px 80px" }}>
                  <h5
                    className="nombreFundacion"
                    style={{ display: "inline-block" }}
                  >
                    {" "}
                    {(state.planta_produccion && state.planta_produccion.nombre) ||
                      "Planta de producción"}
                  </h5>{" "}
                  <span> - </span>
                  <span className="emailFundacion">
                    {" "}
                    {(state.planta_produccion && state.planta_produccion.direccion) ||
                      "Dirección"}
                  </span>
                  <p
                    className="semblanzaFundacion"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {(state.planta_produccion && state.planta_produccion.telefono) ||
                      "Teléfono"}
                  </p>
                  <div
                    id="urlOficialContainer"
                    className="row"
                    style={{ marginTop: "30px" }}
                  >
                    <div className="col-md-1 col-xs-1">
                      <img src="/images/icons/ico_web.png" alt="Sitio web" />
                    </div>
                    <div className="col-md-10 col-xs-10">
                      <a
                        id="url_oficial"
                        href={
                          (state.planta_produccion &&
                            state.planta_produccion.url_oficial) ||
                          "# "
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p>Sitio oficial de la organización</p>
                      </a>
                    </div>
                  </div>
                  <div id="telefonoContainer" className="row">
                    <div className="col-md-1 col-xs-1">
                      <img src="/images/icons/ico_phone.png" alt="Teléfono" />
                    </div>
                    <div className="col-md-10 col-xs-10">
                      <p className="telefonoFundacion">
                        {(state.planta_produccion && state.planta_produccion.telefono) ||
                          "No hay telefono registrado"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "30px" }} id="seccionCausas">
              <div className="row">
                <div className="col-md-9 col-xs-9">
                  <h5 className="title" id="tarjetasTitulo">
                    {state.planta_produccion &&
                      "Tarjetas creadas por " + state.planta_produccion.nombre}
                  </h5>
                </div>
                <div className="col-md-3 col-xs-3">
                  <nav className="navigation" style={{ backgroundColor: "rgba(0,0,0,0)" }}>
                    <a
                      className="btn btn-rounded btn-solid mr-3 px-3 mt-2"
                      href="#"
                      onClick={openModalNuevaTarjeta}
                    >
                      <i className="fas fa-sign-in-alt d-none d-md-inline mr-md-0 mr-lg-2"></i>
                      <span className="d-md-none d-lg-inline">
                        Crear nueva tarjeta de producción
                      </span>
                    </a>
                  </nav>
                </div>
              </div>

              <div className="row" id="tarjetasContainer">
                {state.tarjetas &&
                  state.tarjetas.map((tarjeta) => (
                    <CardTarjetaProduccion tarjeta={tarjeta} key={tarjeta._id} />
                  ))}
              </div>
            </div>

          </div>
        </div>
      </main>
    </div>
  );
}

export default PlantaProduccion;
