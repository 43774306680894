import React, { useEffect, useState } from "react";
import swal from 'sweetalert2';
import CtlDonacionUsuario from "./CtlDonacionUsuario";
import Api from "../util/api";
import Util from "../util/util";

function MisDonaciones(props) {
  const [state, setState] = useState({
    donaciones: null,
  });


  function montoTotalDonado() {
    if (!state.donaciones) return 0;
    var montoTotal = 0;
    for (var i = 0; i < state.donaciones.length; i++) {
      montoTotal += state.donaciones[i].monto;
    }
    
    return montoTotal;
  }

  useEffect(() => {
    async function fetchData() {
      if (!Util.validarJWT()) {
      await swal.fire("¡Ups!", "Es necesario iniciar sesión", "error");
      window.location.href = "/login";
      return;
    }
      if (!props.user) return;
      try {
        //alert(true);
        props.onLoading(true);
        var res = await Api.facturas.donaciones_usuario(props.user._id);
        var donaciones = (await res.json()).facturas;
        if (await Util.requestSuccess(res, true)) {
          setState({
            donaciones,
          });
        }
        console.log(res);
      } catch (e) {
        console.log(e);
      }
      //alert(false);
      props.onLoading(false);
    }
    
    
    fetchData();
    //if (state.donaciones) props.onLoading(false);
  }, [props.user]);
  return (
    <div className="MisDonaciones" style={{ width: "100%", minHeight: "92vh" }}>
      <main className="overflow-hidden bg-6">
        <header className="page header">
          <div className="content">
            <div className="row">
              <div className="col-md-9 col-xs-9">
                <h1 className="display-4 mb-0 nombreUsuario">
                  {(props.user && "Donaciones de " + props.user.nombre) ||
                    "Donaciones del usuario"}
                </h1>
              </div>
            </div>
          </div>
        </header>
        <div className="content">
          <div className="container-fluid">
            <div style={{ marginTop: "30px" }} id="donaciones">
              <h3 className="title" id="donacionesTitulo">
                {(state.donaciones &&
                  props.user &&
                  props.user.nombre +
                  " ha realizado " +
                  state.donaciones.length +
                  " donaciones con un total de $" +
                  montoTotalDonado()) || 0 + "MXN:" ||
                  "Donaciones públicas del usuario:"}
              </h3>
              <p id="lblValidaRfc" style={{ display: "none" }}></p>
              <div id="facturasContainer">
                {state.donaciones &&
                  state.donaciones.map((donacion) => (
                    <CtlDonacionUsuario
                      donacion={donacion}
                      user={props.user}
                      key={donacion._id}
                    />
                  ))}
              </div>
            </div>

            <div
              style={{
                marginTop: "30px",
                marginBottom: "30px",
                display: "none",
              }}
              id="causas"
            >
              <h3 className="title">Causas que apoyan al usuario:</h3>
              <div className="row" id="causasContainer"></div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default MisDonaciones;
