import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import FileBase64 from "react-file-base64";
import Button from "react-bootstrap/Button";
import Api from "../../util/api";
import Util from "../../util/util";
import swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useLoading from "../../hooks/useLoading";
function NuevoProductoProduccion(props) {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        producto: {
            nombre: "",
            descripcion: "",
        },
    });

    async function getFiles(file) {
        startLoading();
        console.log(file);
        if (file.type.search("image/") < 0) {
            await swal.fire("Ups!", 'Solo puedes subir imágenes', "error");
            setState((prevState) => ({
                ...prevState,
                producto: {
                    ...prevState.producto,
                    nueva_foto: {
                        ...prevState.nueva_foto,
                        contenido: null,
                    }
                }
            }));
            stopLoading();
            return;
        }
        if (file.size >= 2000000) {
            await swal.fire("Ups!", 'El archivo no puede ser mayores a 2mb. "' +
                file.name +
                '" es un archivo demasiado grande. Te recomendamos comprimirlo', "error");
            setState((prevState) => ({
                ...prevState,
                producto: {
                    ...prevState.producto,
                    nueva_foto: {
                        ...prevState.nueva_foto,
                        contenido: null,
                    }
                }
            }));
            stopLoading();
            return;
        }
        console.log();
        setState((prevState) => ({
            ...prevState,
            producto: {
                ...prevState.producto,
                nueva_foto: {
                    ...prevState.nueva_foto,
                    contenido: file.base64.split("base64,")[1],
                }
            }
        }));
        stopLoading();
    }

    const handleDateChangeTarjeta = (id, value) => {
        setState((prevState) => ({
            ...prevState,
            producto: {
                ...prevState.producto,
                [id]: value,
            }
        }));
    };

    const handleChangeProducto = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            producto: {
                ...prevState.producto,
                [id]: value,
            },
        }));
    };

    useEffect(() => {
        if (props.productoAEditar) {
            setState((prevState) => ({
                ...prevState,
                producto: props.productoAEditar,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                producto: {
                    nombre: "",
                    descripcion: "",
                    nueva_foto: "",
                },
            }));

        }
    }, [props.productoAEditar]);

    async function clickGuardarProducto() {
        try {
            startLoading();
            var res;
            if (props.productoAEditar) {
                res = await Api.productos_produccion.actualizar(props.productoAEditar._id, state.producto);
            } else {
                res = await Api.productos_produccion.crear(props.organizacion, state.producto);
            }
            stopLoading();
            if (!await Util.requestSuccess(res)) {
                
                await swal.fire("¡Ups!", "Error al guardar producto", "error");
                
                return;
            }
            await swal.fire("¡Listo!", "El producto fué guardado con exito", "success");
            console.log(res);

            //props.consultardocumentos();
            //Aqui no es necesario loading = false, props.consultardocumentos() ya lo hace
            props.onHide();
        } catch (e) {
            console.log(e);
            stopLoading();
            await swal.fire("¡Ups!", "Error al guardar producto", "error");
        }
        props.onHide();
        stopLoading();
    }

    return (
        <div className="NuevoProductoProduccion">
            <Modal {...props} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo producto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <form style={{ width: "100%" }} className="form">
                            <div>
                                <img id="fotoProducto" src={state.producto.foto || ""} alt="Foto de producto" style={{ width: "200px" }} />
                                <div className="row" style={{ marginTop: "15px", marginBottom: "15px" }}>
                                    <div className="col-md-12 col-xs-12" id="cambiarFotoProductoContainer">
                                        <button id="cmdCambiarFotoProducto">Subir foto</button>
                                    </div>
                                    <div className="col-md-6 col-xs-6" style={{ display: "none" }} id="cancelarFotoProductoContainer">
                                        <button id="cmdCancelarFotoProducto">Cancelar</button>
                                    </div>
                                </div>

                                <FileBase64 multiple={false} accept="image/*" onDone={getFiles.bind(this)} />

                            </div>

                            <input type="text" placeholder="Nombre" id="nombre" value={state.producto.nombre || ""} onChange={handleChangeProducto} />
                            <input type="text" placeholder="Descripción (opcional)" id="descripcion" value={state.producto.descripcion || ""} onChange={handleChangeProducto} />
                            <div className="row">
                                <div className="col-md-6 col-xs-6">
                                    <input type="text" placeholder="Contenido (opcional)" id="contenido" value={state.producto.contenido || ""} onChange={handleChangeProducto} />
                                </div>
                                <div className="col-md-6 col-xs-6">
                                    <select type="text" placeholder="Contenido (opcional)" id="unidad_medida" value={state.producto.unidad_medida} onChange={handleChangeProducto}>
                                        <option>Unidades</option>
                                        <option>kg</option>
                                        <option>g</option>
                                        <option>l</option>
                                        <option>ml</option>
                                    </select>
                                </div>
                            </div>

                            <input type="text" placeholder="Código (opcional)" id="codigo" value={state.producto.codigo || ""} onChange={handleChangeProducto} />
                        </form>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={clickGuardarProducto}>
                        Guardar producto
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default NuevoProductoProduccion;