import React from "react";
function CardPlantaProduccion(props) {
    return (
      <div className="col-md-3 col-xs-6" style={{ padding: "10px" }}>
        <a
          href={
            (props.planta &&
              "/app/plantas-suministro/" + props.planta._id) ||
            "#"
          }
        >
          <div className="card">
            <img
              src={(props.planta && props.planta.propietario && props.planta.propietario.foto) || "foto"}
              style={{ width: "100%" }}
              alt={(props.planta && props.planta.nombre) || "Nombre"}
            />
            <div>
              <h5>
                {(props.planta && props.planta.nombre) || "Nombre"}
              </h5>
            </div>
          </div>
        </a>
      </div>
    );
  }
  
  export default CardPlantaProduccion;
  