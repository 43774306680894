import React, { useEffect, useState } from "react";
import Api from "../util/api";
import Util from "../util/util";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import ItinerarioItem from "./ItinerarioItem";
import CtlActualizacion from "./CtlActualizacion";
import swal from 'sweetalert2';

function Causa(props) {
  const [state, setState] = useState({
    causa: null,
    causaId: "",
    actualizaciones: null,
    admin: false,
    montoTotalIngresos: 0,
  });

  useEffect(() => {
    async function consultarCausa(causaId) {
      try {
        props.onLoading(true);
        var res = await Api.causas.get(causaId);
        await Util.requestSuccess(res);
        res = await res.json();
        console.log(res);
        setState((prevState) => ({
          ...prevState,
          admin: res.admin,
          causa: res.causa,
          causaId: causaId,
        }));
        consultarIngresos(res.causa.fundacion._id);
      } catch (e) {
        console.log(e);
        await swal.fire("¡Ups!", 'Tenemos problemas para consultar la información de esta campaña', "error");
      }
      //props.onLoading(false);
    }

    async function consultarIngresos(_id) {
      //props.onLoading(true);
      try {
        var res = await Api.facturas.listar_ingresos(
          _id,
          "Organizacion"
        );
        await Util.requestSuccess(res);
        res = await res.json();
        console.log(res);
        var ingresos = res.facturas;
        var montoTotalIngresos = 0;
        for (var i = 0; i < ingresos.length; i++) {
          montoTotalIngresos += consultarMontoFactura(ingresos[i]);
        }
        setState((prevState) => ({
          ...prevState,
          ingresos,
          montoTotalIngresos,
        }));
      } catch (e) { }
      props.onLoading(false);
    }

    function consultarMontoFactura(factura) {
      //alert(factura.monto + " " + factura.monto_especie);
      if (factura.monto > 0) {
        return factura.monto;
      } else return factura.monto_especie;
    }

    if (state.causaId.length === 0) {
      var initialId = window.location.pathname.replace("/app/causas", "");
      if (initialId.length > 0) {
        consultarCausa(initialId.replace("/", ""));
      }
    }
  }, [state.causaId]);



  async function consultarActualizacionesCausa() {
    try {
      props.onLoading(true);
      var res = await Api.causas.actualizaciones.list(state.causaId);
      await Util.requestSuccess(res);
      res = await res.json();
      console.log(res);
      setState((prevState) => ({
        ...prevState,
        actualizaciones: res.actualizaciones,
      }));

    } catch (e) {
      console.log(e);
    }
    props.onLoading(false);
  }

  function renderFundacionesInvitadas() {
    if (
      !state.causa ||
      !state.causa.fundaciones_invitadas ||
      state.causa.fundaciones_invitadas.length <= 0
    )
      return null;
    else
      return (
        <span>
          {" "}
          y{" "}
          <a
            id="fundacionLink"
            href={
              state.causa &&
              "/app/organizaciones/" +
              state.causa.fundaciones_invitadas[0].fundacion._id
            }
          >
            <h5 style={{ display: "inline-block" }}>
              {state.causa &&
                state.causa.fundaciones_invitadas[0].fundacion.nombre}
            </h5>{" "}
          </a>
        </span>
      );
  }
  return (
    <main className="overflow-hidden bg-6" style={{ width: "100%", minHeight: "92vh" }}>
      <header className="page header">
        <div id="modalNuevaActualizacion" className="modal fade" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title ml-auto">Nueva actualización</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <img
                    id="fotoActualizacion"
                    src=""
                    alt="Nueva actualización"
                    style={{ width: "100%", maxHeight: "300px" }}
                  />
                  <div
                    className="row"
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                  >
                    <div
                      className="col-md-12 col-xs-12"
                      id="cambiarFotoActualizacionContainer"
                    >
                      <button
                        id="cmdCambiarFotoActualizacion"
                        className="btn btn-default"
                      >
                        Subir foto
                      </button>
                    </div>
                    <div
                      className="col-md-6 col-xs-6"
                      style={{ display: "none" }}
                      id="cancelarFotoActualizacionContainer"
                    >
                      <button
                        id="cmdCancelarFotoActualizacion"
                        className="btn btn-default"
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>

                  <input
                    type="file"
                    id="inputFotoActualizacion"
                    accept="image/png, image/jpeg"
                    style={{ display: "none" }}
                  />
                </div>
                <input
                  type="text"
                  id="txtNombreActualizacion"
                  placeholder="Nombre de la actualización"
                  style={{ width: "100%" }}
                />
                <textarea
                  placeholder="Contenido"
                  id="txtDescripcionActualizacion"
                  rows="4"
                  style={{ width: "100%" }}
                  className="mt-2"
                ></textarea>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  id="cmdGuardarActualizacion"
                >
                  Guardar
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="row">
            <div className="col-md-9 col-xs-9">
              <h3 className="title causaNombre">
                {state.causa && state.causa.nombre}
              </h3>
              {/*<div className="fb-share-button" data-href="http://127.0.0.1:3000/causas/5cfb0c54dac5810049e2ef1a" data-layout="button_count" data-size="large"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.lifechain.mx%2Fcausas%2F5cfb0c54dac5810049e2ef1a&amp;src=sdkpreparse" className="fb-xfbml-parse-ignore">Compartir</a></div>*/}
            </div>
            <div className="col-md-3 col-xs-3">
              <nav className="navigation">
                <a
                  id="cmdEditarCausa"
                  className="btn btn-rounded btn-solid mr-3 px-3 mt-2"
                  href={
                    (state.causa && "/app/causas/editar/" + state.causa._id) ||
                    "# "
                  }
                >
                  <i className="fas fa-sign-in-alt d-none d-md-inline mr-md-0 mr-lg-2"></i>
                  <span className="d-md-none d-lg-inline">Editar campaña</span>
                </a>
                <a
                  id="cmdNuevaActualizacion"
                  className="btn btn-rounded btn-solid mr-3 px-3 mt-2"
                  href="# "
                  data-toggle="modal"
                  data-target="#modalNuevaActualizacion"
                >
                  <i className="fas fa-sign-in-alt d-none d-md-inline mr-md-0 mr-lg-2"></i>
                  <span className="d-md-none d-lg-inline">
                    Nueva Actualización
                  </span>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-xs-12" style={{ padding: "10px" }}>
              <div clas="row">
                <div className="col-md-0 col-xs-2"></div>
                <div className="card col-md-12 col-xs-8">
                  <img
                    id="causaFoto"
                    src={state.causa && state.causa.fotos}
                    alt={
                      (state.causa && state.causa.nombre) || "Foto de campaña"
                    }
                    style={{ width: "100%", height: "100%" }}
                  />
                  <div>
                    <b>
                      <h5 className="causaNombre">
                        {state.causa && state.causa.nombre}
                      </h5>
                    </b>
                  </div>
                </div>
                <div className="col-md-0 col-xs-2"></div>
              </div>
            </div>
            <div className="col-md-9 col-xs-12">
              <div className="p-3 p-md-5">
                <div id="fundacionesContainer">
                  <a
                    id="fundacionLink"
                    href={
                      state.causa &&
                      "/app/organizaciones/" + state.causa.fundacion._id
                    }
                  >
                    <h5 style={{ display: "inline-block" }}>
                      {state.causa && state.causa.fundacion.nombre}
                    </h5>{" "}
                  </a>
                  {renderFundacionesInvitadas()}
                </div>
                <span className="causaFundacionEmail">
                  {state.causa && state.causa.fundacion.email}
                </span>
                <p
                  className="causaDescripcion"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {state.causa && state.causa.descripcion}
                </p>
                {/*<div id="objetivoContainer" style="white-space: pre-wrap;">
                  <h5>Objetivo:</h5>
                  <p className="causaObjetivo"></p> 
    </div>*/}
                <div
                  id="urlOficialContainer"
                  className="row"
                  style={{ marginTop: "30px" }}
                >
                  <div className="col-md-1 col-2">
                    <img src="/images/icons/ico_web.png" alt="Sitio web" />
                  </div>
                  <div className="col-md-10 col-10">
                    <a
                      id="url_oficial"
                      href={
                        (state.causa && "//" + state.causa.url_oficial) || "# "
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>Sitio oficial de la campaña</p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row" id="materiaRequeridaContainer">
                <div className="col-md-4 col-xs-4">
                  <p className="numeroNecesidad">
                    $
                    {(state.causa &&
                      Util.numberWithCommas(state.causa.meta_recaudacion)) ||
                      0}
                  </p>
                  <p className="descripcionNecesidad">Meta de recaudación</p>
                </div>
                <div className="col-md-4 col-xs-4">
                  <p className="numeroNecesidad">
                    {(state.causa &&
                      Util.numberWithCommas(
                        state.causa.numero_beneficiarios
                      )) ||
                      0}
                  </p>
                  <p className="descripcionNecesidad">
                    {(state.causa && state.causa.descripcion_beneficiarios) ||
                      "Descripción de beneficiarios"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row" id="progresoContainer">
            <div className="col-md-4 col-xs-4"></div>
            <div className="col-md-4 col-xs-4">
              <div className="chartjs-wrapper">
                <canvas className="chartjs-gauge"></canvas>
                <p className="descripcionNecesidad">Recaudado</p>
                <p id="lblTotalRecaudado" className="descripcionNecesidad">
                  ${Util.numberWithCommas(state.montoTotalIngresos) || 0}/$
                  {(state.causa &&
                    Util.numberWithCommas(state.causa.meta_recaudacion)) ||
                    0}
                </p>
              </div>
            </div>
            <div className="col-md-4 col-xs-4"></div>
          </div>
          <div className="row" style={{ margin: "35px 0px" }}>
            <div className="col-md-2 col-xs-2"></div>
            <div className="col-md-4 col-xs-4" id="cmdDonarContainer">
              <a
                href="/donaciones/nueva/<%= causa.id %>"
                id="cmdDonar"
                target="_blank"
              >
                <div className="button">Donar</div>
              </a>
            </div>

            <div
              className="col-md-4 col-xs-4"
              id="cmdPostularBeneficiarioContainer"
            >
              <a href="# ">
                <div className="button" id="cmdPostularBeneficiario">
                  Postular Beneficiario
                </div>
              </a>
            </div>

            <div className="col-md-2 col-xs-2"></div>
          </div>
          <p align="center" id="videoContainer" style={{ display: "none" }}>
            <iframe
              id="videoView"
              width="620"
              height="415"
              src=""
              title="Video de campaña"
            ></iframe>
          </p>
          <Tabs>
            <TabList>
              <Tab>Objetivo</Tab>
              <Tab>Donadores</Tab>
              <Tab>Beneficiarios</Tab>
              <Tab>Itinerario</Tab>
              <Tab onClick={() => consultarActualizacionesCausa()}>
                Actualizaciones
              </Tab>
            </TabList>

            <TabPanel>
              <div id="objetivoContainer" style={{ whiteSpace: "pre-wrap" }}>
                <p className="causaObjetivo">
                  {(state.causa && state.causa.objetivo) ||
                    "Por el momento, la fundación no registró ningún objetivo"}
                </p>
              </div>
            </TabPanel>
            <TabPanel>
              <div id="donadoresContainer" className="row"></div>
            </TabPanel>
            <TabPanel>
              <div id="beneficiariosContainer" className="row"></div>
            </TabPanel>
            <TabPanel>
              <p className="m-5">
                Aquí puedes consultar el plan de trabajo diseñado para esta
                campaña
              </p>
              <div className="container-fluid">
                <ul id="itinerarioContainer" className="itinerario-pasos">
                  {state.causa &&
                    state.causa.itinerario &&
                    state.causa.itinerario.map((etapa) => (
                      <ItinerarioItem etapa={etapa} key={etapa._id} />
                    ))}
                </ul>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row">
                <div className="col-md-9 col-12">
                  <p className="m-5">
                    Aquí puedes encontrar las actualizaciones reportadas por el
                    creador de la campaña
                  </p>
                </div>
                <div className="col-md-3 col-12">
                  <nav className="navigation adminCmd mt-md-4 mb-4 ml-4 ml-md-0">
                    <a
                      id="cmdNuevaActualizacion"
                      className="btn btn-rounded btn-solid mr-3 px-3 mt-2"
                      href="# "
                      data-toggle="modal"
                      data-target="#modalNuevaActualizacion"
                    >
                      <i className="fas fa-sign-in-alt d-none d-md-inline mr-md-0 mr-lg-2"></i>
                      <span className="d-md-none d-lg-inline">
                        Nueva Actualización
                      </span>
                    </a>
                  </nav>
                </div>
              </div>

              <div className="container-fluid" id="actualizacionesContainer">
                {state.actualizaciones &&
                  state.actualizaciones.map((actualizacion) => (
                    <CtlActualizacion
                      actualizacion={actualizacion}
                      key={actualizacion._id}
                    />
                  ))}
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </main>
  );
}

export default Causa;
