import React from "react";
import LandingIndex from "./pages/landing/LandingIndex";
import PoliticaPrivacidad from "./pages/landing/PoliticaPrivacidad";
import TerminosCondiciones from "./pages/landing/TerminosCondiciones";
import About from "./pages/landing/About";
import Contact from "./pages/landing/Contact";
import Blog from "./pages/blog/Blog";
import BlogNFT from "./pages/blog/BlogNFT";
import BlogInvestigacionDonacion from "./pages/blog/BlogInvestigacionDonacion";
import BlogDescargarResultadosDonadores from "./pages/blog/BlogDescargarResultadosDonadores";
import BlogCadenaSuministro from "./pages/blog/BlogCadenaSuministro";
import BlogProtegerCertificadosVacunacion from "./pages/blog/BlogProtegerCertificadosVacunacion";
import Prensa from "./pages/landing/Prensa";
import PrensaCriptonoticiasPesoxPeso from "./pages/prensa/PrensaCriptonoticiasPesoxPeso";
import PrensaCuceaTalent from "./pages/prensa/PrensaCuceaTalent";
import PrensaGanadoresTalent from "./pages/prensa/PrensaGanadoresTalent";
import PrensaImagenTalent from "./pages/prensa/PrensaImagenTalent";
import PrensaInformadorTalent from "./pages/prensa/PrensaInformadorTalent";
import Docs from "./pages/api/Docs";
import DocsCertificacionDocumentos from "./pages/api/DocsCertificacionDocumentos";
import ProductoCertificacionDocumentos from "./pages/productos/CertificacionDocumentos";
import ProductoConsultorias from "./pages/productos/Consultorias";
import ProductoSeguimientoDonaciones from "./pages/productos/SeguimientoDonaciones";
import ProductoSeguimientoSuministros from "./pages/productos/SeguimientoSuministros";
import ProductoGobiernoDigital from "./pages/productos/GobiernoDigital";
import Login from "./pages/landing/Login";
import ForgotPass from "./pages/landing/ForgotPass";
import Error404 from "./pages/error/Error404";
import SignUp from "./pages/landing/SignUp";
import ValidarEmail from "./components/ValidarEmail";
import CambiarPass from "./components/CambiarPass";
import AppContainer from "./components/AppContainer";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import LoadingIndicator from "./components/LoadingIndicator";

function App() {
  return (
    <div className="App">
      <LoadingIndicator />
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={LandingIndex} />
          <Route exact path="/politica-privacidad" component={PoliticaPrivacidad} />
          <Route exact path="/terminos-condiciones" component={TerminosCondiciones} />
          <Route exact path="/forgot" component={ForgotPass} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/blog/nft-criptoarte" component={BlogNFT} />
          <Route exact path="/blog/investigacion-donadores" component={BlogInvestigacionDonacion} />
          <Route exact path="/blog/gracias-descargar-resultados-donadores" component={BlogDescargarResultadosDonadores} />
          <Route exact path="/blog/cadena-suministro" component={BlogCadenaSuministro} />
          <Route exact path="/blog/como-proteger-certificados-de-vacunacion" component={BlogProtegerCertificadosVacunacion} />
          <Route exact path="/prensa" component={Prensa} />
          <Route exact path="/prensa/criptonoticias-donaciones-impuestos" component={PrensaCriptonoticiasPesoxPeso} />
          <Route exact path="/prensa/ganadores-talent-land" component={PrensaGanadoresTalent} />
          <Route exact path="/prensa/imagen-talent-land" component={PrensaImagenTalent} />
          <Route exact path="/prensa/cucea-talent-land" component={PrensaCuceaTalent} />
          <Route exact path="/prensa/informador-talent-land" component={PrensaInformadorTalent} />
          <Route exact path="/docs" component={Docs} />
          <Route exact path="/docs/certificacion-documentos" component={DocsCertificacionDocumentos} />
          <Route exact path="/certificacion-documentos" component={ProductoCertificacionDocumentos} />
          <Route exact path="/consultorias" component={ProductoConsultorias} />
          <Route exact path="/seguimiento-donaciones" component={ProductoSeguimientoDonaciones} />
          <Route exact path="/seguimiento-suministros" component={ProductoSeguimientoSuministros} />
          <Route exact path="/gobierno-digital" component={ProductoGobiernoDigital} />
          <Route exact path="/usuarios/verificar-email/:id" component={ValidarEmail} />
          <Route exact path="/usuarios/olvide-contrasena/:id" component={CambiarPass} />
          <Redirect from="/documentos/validar/:id" to="/app/documentos/validar/:id" />
          <Route path="/app" component={AppContainer} />
          <Route component={Error404} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;