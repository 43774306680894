import React from "react";
function CardOrganizacion(props) {
  return (
    <div className="col-md-3 col-xs-6" style={{ padding: "10px" }}>
      <a
        href={
          (props.organizacion &&
            "/app/organizaciones/" + props.organizacion._id) ||
          "#"
        }
      >
        <div className="card">
          <img
            src={(props.organizacion && props.organizacion.foto) || "/admin/user.png"}
            style={{ width: "100%" }}
            alt={(props.organizacion && props.organizacion.nombre) || "Organización"}
          />
          <div>
            <h5>
              {(props.organizacion && props.organizacion.nombre) || "Nombre"}
            </h5>
          </div>
        </div>
      </a>
    </div>
  );
}

export default CardOrganizacion;
