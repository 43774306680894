import React from "react";
function CtlSeguimientoDonacionItem(props) {
  return (
    <div
      className="nav-step nav-step-active"
      style={{ width: 100 / props.longitudLineaSeguimiento + "%" }}
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="step-dash-line left"
      >
        <line x1="4" y1="3" x2="100%" y2="3"></line>
      </svg>
      <a href={"/app/transacciones/" + props.entidad._id}>
        <div className="step-content">
          <div className="step-icon shadow">
            <div className="image-cropper">
              <img
                src={
                  (props.entidad && props.entidad.foto) || "/images/userico.png"
                }
                alt="avatar"
                className="profile-pic"
                style={{
                  width: "80px",
                  height: "80px",
                  display: "block",
                  borderRadius: "50%",
                  webkitBorderRadius: "50%",
                  mozBorderRadius: "50%",
                }}
              />
            </div>
          </div>
          <p className="light-desc">{props.entidad && props.entidad.nombre}</p>
        </div>
      </a>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="step-dash-line right"
      >
        <line x1="6" y1="3" x2="100%" y2="3"></line>
      </svg>
    </div>
  );
}

export default CtlSeguimientoDonacionItem;
