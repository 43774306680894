import React from "react";
import Util from "../util/util";
//import swal from 'sweetalert2';

function CtlActualizacion(props) {
  return (
    <div className="col-md-12 col-12 mtb-2 stdBorder p-4">
      <div className="row">
        <div className="col-md-1 col-12" style={{ textAlign: "center" }}>
          <b>
            <p>
              {props.actualizacion &&
                Util.formatDateShort(props.actualizacion.fecha_registro)}
            </p>
          </b>
        </div>
        <div className="col-md-10 col-12">
          <h3>{props.actualizacion && props.actualizacion.nombre}</h3>
        </div>
        <div className="col-md-1 col-12">
          <a
            onClick="alert(\'Editar información\');"
            href="# "
            className="adminCmd"
          >
            <img
              src="/images/editar.svg"
              style={{ width: "25px" }}
              alt="Editar actualización"
            />
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 col-0"></div>
        <div className="col-md-11 col-12">
          <p>{props.actualizacion && props.actualizacion.descripcion}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 col-0"></div>
        <div className="col-md-11 col-12">
          <img
            src={props.actualizacion && props.actualizacion.foto}
            style={{ width: "100%" }}
            alt={
              (props.actualizacion && props.actualizacion.nombre) ||
              "Actualización"
            }
          />
        </div>
      </div>
    </div>
  );
}

export default CtlActualizacion;
