import React, { useEffect, useState } from "react";
import swal from 'sweetalert2';
import Api from "../util/api";
import CtlDocumento from "./CtlDocumento";
import ModalCertificarDocumentos from "./modal/CertificarDocumentos";
import ModalViewPdf from "./modal/ViewPdf";
import ModalViewImg from "./modal/ViewImg";
import ModalViewText from "./modal/ViewText";
import ModalViewAudio from "./modal/ViewAudio";
import Util from "../util/util";
import useLoading from "../hooks/useLoading";
import useUser from "../hooks/useUser";

function DocumentosLista(props) {
  const { startLoading, stopLoading } = useLoading();
  const { user } = useUser();
  const [state, setState] = useState({
    admin: false,
    user: { saldo_certificar_docs: 0 },
    documentos_a_certificar: null,
    document_list: null,
    loading: false,
    error: null,
    showModalCertificar: false,
    showModalPdf: false,
    showModalImg: false,
    showModalAudio: false,
    showModalText: false,
    srcUrlPdf: null,
    srcUrlImg: null,
    srcUrlAudio: null,
    srcUrlText: null,
  });
  useEffect(() => {

    async function fetchData() {
      try {
        if (!user?._id) return;
        startLoading();
        var res = await Api.usuarios.get(user._id);
        await Util.requestSuccess(res, true);
        if (await Util.requestSuccess(res)) {

        } else {

        }
        var user = (await res.json()).usuario;
        console.log(user);
        setState((prevState) => ({
          ...prevState,
          user,
        }));
      } catch (e) {
        console.log(e);
      }

    }

    //fetchData();
    consultarDocumentos();
  }, [user]);

  async function consultarDocumentos() {
    try {
      if (!user?._id) return;
      startLoading();
      var res = await Api.documentos.list(user._id);
      await Util.requestSuccess(res, true);
      var document_list = await res.json();
      console.log(document_list);
      var bytes = 0;
      for (var document in document_list) {
        try {
          if (!document_list[document].bytes) continue;
          bytes += parseInt(document_list[document].bytes);
        } catch (e) {
          console.log(e);
        }

      }
      console.log(bytes);

      setState((prevState) => ({
        ...prevState,
        document_list,
        bytes
      }));
      stopLoading();
    } catch (e) {
      console.log(e);
      window.location.href = "/login";
    }
    stopLoading();
  }

  function closeModalCertificar() {
    setState({
      ...state,
      showModalCertificar: false,
    });
  }
  function openModalCertificar() {
    setState({
      ...state,
      showModalCertificar: true,
    });
  }
  function openModalDocument(srcUrl, mime) {
    if (!mime) mime = "";
    if (srcUrl) {
      if (mime.search("pdf") > 0 || srcUrl.search(".pdf") > 0) {
        setState({
          ...state,
          showModalPdf: true,
          srcUrlPdf: srcUrl,
        });
      } else if (mime.search("audio") >= 0 || srcUrl.search(".mp3") > 0) {
        setState({
          ...state,
          showModalAudio: true,
          srcUrlAudio: srcUrl,
        });
      } else if (
        mime.search("image") >= 0 ||
        srcUrl.search(".jpg") > 0 ||
        srcUrl.search(".png") > 0
      ) {
        setState({
          ...state,
          showModalImg: true,
          srcUrlImg: srcUrl,
        });
      } else if (mime === "Unknown" || mime.search("xml") >= 0) {
        setState({
          ...state,
          showModalText: true,
          srcUrlText: srcUrl,
        });
      } else {
        window.location.href = srcUrl;
      }
    }
  }

  function closeModals() {
    setState({
      ...state,
      showModalPdf: false,
      showModalText: false,
      showModalImg: false,
      showModalAudio: false,
    });
  }
  return (
    <div className="DocumentosLista" style={{ width: "100%" }}>
      <main className="overflow-hidden bg-6" style={{ minHeight: "95vh" }}>
        <header className="page header">
          <ModalViewAudio
            show={state.showModalAudio}
            onHide={closeModals}
            srcurl={state.srcUrlAudio}
          />
          <ModalViewImg
            show={state.showModalImg}
            onHide={closeModals}
            srcurl={state.srcUrlImg}
          />
          <ModalViewText
            show={state.showModalText}
            onHide={closeModals}
            srcurl={state.srcUrlText}
          />
          <ModalViewPdf
            show={state.showModalPdf}
            onHide={closeModals}
            srcurl={state.srcUrlPdf}
          />
          <ModalCertificarDocumentos
            show={state.showModalCertificar}
            onHide={closeModalCertificar}
            consultardocumentos={consultarDocumentos}
            onLoading={props.onLoading}
          />

          <div className="content">
            <div className="row">
              <div className="col-md-9 col-xs-9">
                <h1 className="display-4 mb-0 nombreUsuario">
                  {(
                    user?.nombre + " " + user?.apellidos) ||
                    "Perfil de usuario"}
                </h1>
              </div>
            </div>
          </div>
        </header>
        <div className="content">
          <div className="container-fluid">
            <div style={{ marginTop: "30px" }} id="donaciones">
              <div className="row">
                <div className="col-md-9 col-xs-9">
                  <h4>Crédito disponible:</h4>
                  <p id="creditoCertificaciones">
                    Puedes certificar {user?.saldo_certificar_docs || 0} archivos con Blockchain
                  </p>
                  <h5 className="title">
                    Tienes {state.document_list?.length || 0}{" "} archivos certificados con Blockchain
                  </h5>
                  <h6 className="title">
                    Estás usando {Util.bytesToSize(state.bytes) || "0 Bytes"} de almacenamiento
                  </h6>
                </div>
                <div className="col-md-3 col-xs-3">
                  <nav
                    className="navigation"
                    style={{ backgroundColor: "rgba(0,0,0,0)" }}
                  >
                    <a
                      className="btn btn-rounded btn-solid mr-3 px-3 mt-2 editarPerfilLink"
                      href="# "
                      onClick={openModalCertificar}
                      id="cmdCertificar"
                    >
                      <span className="d-md-none d-lg-inline">
                        Certificar archivos
                      </span>
                    </a>
                  </nav>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 col-xs-2">
                  <p>Folio</p>
                </div>
                <div className="col-md-2 col-xs-2">
                  <p>Fecha</p>
                </div>
                <div className="col-md-2 col-xs-2">
                  <p>Nombre</p>
                </div>
                <div className="col-md-2 col-xs-2">
                  <p>Hash de Archivo</p>
                </div>
                <div className="col-md-1 col-xs-1">
                  <p>Ver Archivo</p>
                </div>
                <div className="col-md-1 col-xs-1">
                  <p>Certificado</p>
                </div>
                <div className="col-md-1 col-xs-1">
                  <p>Blockchain</p>
                </div>
                <div className="col-md-1 col-xs-1">
                  <p>Privado</p>
                </div>
              </div>
              <div id="documentosContainer">
                {
                  state.document_list?.map((documento) => (
                    <CtlDocumento
                      key={documento._id}
                      documento={documento}
                      admin={true}
                      openModalDocument={openModalDocument}
                      onLoading={props.onLoading}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default DocumentosLista;
