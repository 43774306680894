import React from "react";
import Util from "../util/util";
import Api from "../util/api";
import swal from 'sweetalert2';

function CtlSeguimientoEgreso(props) {
  async function consultarUrl() {
    props.onLoading(true);
    try {
      var res = await Api.facturas.getUrl(props.transaccion._id);
      res = await res.json();
      console.log(res);
    } catch (e) {
      await swal.fire("¡Ups!", "Error al consultar archivo", "error");
    }
    props.onLoading(false);
  }

  function guardarPasoEgreso() {
    var lineaSeguimiento = JSON.parse(
      sessionStorage.getItem("lineaSeguimiento")
    );
    if (!lineaSeguimiento) lineaSeguimiento = [];
    console.log(lineaSeguimiento);
    lineaSeguimiento.push(props.entidad);
    sessionStorage.setItem(
      "lineaSeguimiento",
      JSON.stringify(lineaSeguimiento)
    );
  }

  function renderEmisor() {
    if (props.transaccion.emisor && props.transaccion.emisor._id) {
      return (
        <a
          onClick={() => {
            guardarPasoEgreso();
          }}
          href={"/app/transacciones/" + props.transaccion.emisor._id}
        >
          {props.transaccion.emisor.nombre}
        </a>
      );
    } else if (props.transaccion.razon_social_emisor) {
      return <p>{props.transaccion.razon_social_emisor}</p>;
    } else if (props.transaccion.rfc_emisor) {
      return <p>{props.transaccion.rfc_emisor}</p>;
    } else {
      return <p>Anónimo</p>;
    }
  }
  return (
    <div className="row stdBorder">
      <div className="col-md-2 col-xs-2">
        <p>
          {props.transaccion &&
            Util.formatDateShort(props.transaccion.fecha_transaccion)}
        </p>
      </div>
      <div className="col-md-2 col-xs-2">
        <p>
          $
          {(props.transaccion &&
            (props.transaccion.monto > 0 && Util.numberWithCommas(props.transaccion.monto)) ||
            (props.transaccion.monto_especie > 0 && Util.numberWithCommas(props.transaccion.monto_especie)) || 0)}
        </p>
      </div>
      <div className="col-md-3 col-xs-3">{renderEmisor()}</div>
      <div className="col-md-2 col-xs-2">
        <p>{props.transaccion.categoria}</p>
      </div>
      <div className="col-md-2 col-xs-2">
        <a onClick={()=>consultarUrl()} href="# " className="mr-1">
          <img
            src="/images/documento.svg"
            alt="Ver documento"
            style={{ width: "25px" }}
          />
        </a>
        ';
        <a
          href={
            process.env.REACT_APP_URL_ETHERSCAN + "/tx/" + props.transaccion.hash
          }
          target="_blank"
          rel="noreferrer"
          className="mr-1"
        >
          <img
            src="/images/blockchain.svg"
            alt="Ver en blockchain"
            style={{ width: "30px" }}
          />
        </a>
        ';
        <a
          data-toggle="tooltip"
          data-placement="bottom"
          title={props.transaccion.concepto}
          href="# "
        >
          <img
            src="/images/comentario.svg"
            alt="Consultar concepto"
            style={{ width: "30px" }}
          />
        </a>
        ';
      </div>
    </div>
  );
}

export default CtlSeguimientoEgreso;
