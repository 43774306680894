import React, { useState } from "react";
import Util from "../util/util";
import Api from "../util/api";
import swal from 'sweetalert2';

function crearHrefEmisor(donacion) {
  if (donacion.emisor) {
    return (
      <a href={"/organizaciones/" + donacion.emisor._id}>
        {donacion.emisor.nombre}
      </a>
    );
  } else {
    return "Anonimo";
  }
}

function CtlDonacionUsuario(props) {
  const [state, setState] = useState({
    donacion: props.donacion,
  });

  async function handleChangePrivacity(e) {
    try {
      var res = await Api.facturas.cambiarPrivacidad(props.donacion._id, {
        anonimo_receptor: !props.donacion.anonimo_receptor,
        anonimo_email: !props.donacion.anonimo_receptor,
      });
      res = await res.json();
      console.log(res);
      if (await Util.requestSuccess(res)) {
        await swal.fire("¡Ups!", "No se pudo actualizar la privacidad de tu donación", "error");
        return;
      }
      setState((prevState) => ({
        ...prevState,
        donacion: {
          ...prevState.donacion,
          anonimo_receptor: !prevState.donacion.anonimo_receptor,
          anonimo_email: !prevState.donacion.anonimo_receptor,
        },
      }));
      await swal.fire("Listo!", "La privacidad de tu donación fue actualizada", "success");
    } catch (e) {
      await swal.fire("¡Ups!", "No se pudo actualizar la privacidad de tu donación", "error");
    }
  }
  return (
    <div className="row stdBorder" id="donacion'+i+'">
      <div className="col-md-1 col-xs-1">
        <img src="/images/icons/ico_heart.png" alt="Donación" />
      </div>
      <div className="col-md-9 col-xs-9">
        {crearHrefEmisor(props.donacion)}
        <span>
          {props.donacion &&
            " - " + Util.formatDateShort(props.donacion.fecha_transaccion)}
        </span>
        <p className="mt-4">{props.donacion && props.donacion.monto}</p>
        <p>{props.donacion && props.donacion.concepto}</p>
        <div>
          <p>{props.donacion.hash}</p>
          <a href={"/app/transacciones/" + props.user._id}>
            <button>Ver trazabilidad</button>
          </a>
          <a
            href={
              process.env.REACT_APP_URL_ETHERSCAN + "tx/" + props.donacion.hash
            }
            target="_blank"
            rel="noreferrer"
          >
            <button>Ver en blockchain</button>
          </a>
        </div>
      </div>
      <div className="col-md-2 col-xs-2">
        <input
          type="checkbox"
          name="anonimo"
          value="anonimo"
          id="'+facturas[i]._id+'"
          onChange={handleChangePrivacity}
          checked={state.donacion.anonimo_receptor}
        />
        No quiero que aparezca mi nombre
        <br />
        <img
          src={
            (props.donacion &&
              props.donacion.emisor &&
              props.donacion.emisor.foto) ||
            "/admin/user.png"
          }
          alt={
            props.donacion &&
            props.donacion.emisor &&
            props.donacion.emisor.nombre
          }
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
}

export default CtlDonacionUsuario;
