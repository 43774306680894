import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import swal from 'sweetalert2';
import Api from "../../util/api";
import sha256 from 'sha256';
import FileBase64 from "react-file-base64";
import {Buffer} from 'buffer/';
import Util from "../../util/util";
import settings from "../../config/settings";
import useLoading from "../../hooks/useLoading";
function ValidarDocumento(props) {
  const { startLoading, stopLoading } = useLoading();


  async function getFiles(file) {
    try {
      startLoading();
      var input = file.base64.split("base64,")[1];
      var hashSha256 = sha256(Buffer.from(input, "base64"));
      console.log(hashSha256);
      var res = await Api.documentos.get(hashSha256);
      if (!await Util.requestSuccess(res)) {
        await swal.fire("¡Ups!", "Archivo no encontrado", "error");
        stopLoading();
        return;
      }
      res = await res.json();
      console.log(res);
      console.log(res.blockchain);
      await swal.fire("¡Archivo encontrado!", 
      "Fué certificado por "+res.emisor.nombre+
      " el día "+Util.formatDateShort(res.fecha_certificacion)+
      '. Puedes ver <a href="'+
      ((res.blockhain==="Polygon")?(settings.url.etherscan_explorer+"/tx/"+res.txHash):(settings.url.lacchain_explorer+"/tx/"+res.txHash))+
      '" target="_blank">aquí</a> la transacción en Blockchain. Folio de certificación: '+
      res._id+".", "success");
      console.log(res);
    } catch (e) {
      console.log(e);
      await swal.fire("¡Ups!", "Existen problemas para validar archivo", "error");
    }
    stopLoading();
  }

  return (
    <div className="ValidarDocumento">
      <Modal {...props} size="lg">
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title>Verificar archivo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="row">
                <div className="col-md-2 col-xs-2"></div>
                <div className="col-md-8 col-xs-8">
                  <form className="formUploadFile" style={{ width: "100%" }}>
                    <FileBase64 multiple={false} onDone={getFiles.bind(this)} />
                    <p>Arrastra aquí el archivo que quieres validar.</p>
                  </form>
                </div>
              </div>
              <div id="documentosPorSubirContainer" style={{ display: "none" }}>
                <p id="subtitle">Lista de documentos a verificar.</p>
                <div className="row" style={{ background: "#ccc" }}>
                  <div className="col-md-2 col-xs-2">
                    <p>Fecha</p>
                  </div>
                  <div className="col-md-2 col-xs-2">
                    <p>Vigencia</p>
                  </div>
                  <div className="col-md-2 col-xs-2">
                    <p>Nombre</p>
                  </div>
                  <div className="col-md-2 col-xs-2">
                    <p>Hash de Archivo</p>
                  </div>
                  <div className="col-md-2 col-xs-2"></div>
                  <div className="col-md-2 col-xs-2"></div>
                </div>
                <div id="filesContainer"></div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide}>
              Cerrar
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
}

export default ValidarDocumento;
