import React, { useEffect, useState } from "react";
import Api from "../util/api";
import ModalComprarCertificacionDocumentos from "./modal/ComprarCertificacionDocumentos";
import Util from "../util/util";
import swal from 'sweetalert2';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import useLoading from "../hooks/useLoading";

function DocumentosComprar(props) {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        admin: false,
        user: { saldo_certificar_docs: 0 },
        loading: false,
        error: null,
        showModalComprar: false,
        referenciaPaquete: null,
        precio: null,
        paqueteActual: "Gratuito",
    });
    useEffect(() => {
        async function fetchData() {
            try {
                startLoading();
                var res = await Api.suscripciones.activa();
                console.log(res);
                if(res.status===404){
                    stopLoading();
                    return;
                }
                if (!await Util.requestSuccess(res)) {
                    stopLoading();
                    await swal.fire("¡Ups!", "Error al consultar suscripcion activa", "error");
                    
                    return;
                }
                res = (await res.json());
                setState((prevState) => ({
                    ...prevState,
                    paqueteActual: res.suscripcion.paquete.nombre,
                }));
                console.log(res);
            } catch (e) {
                console.log(e);
            }
            stopLoading();
        }

        fetchData();
    }, []);

    function abrirModalPago(referenciaPaquete, precio) {
        setState((prevState) => ({
            ...prevState,
            referenciaPaquete,
            precio,
            showModalComprar: true
        }));
    }

    function closeModalComprar() {
        setState({
            ...state,
            showModalComprar: false,
        });
    }

    return (
        <div className="DocumentosComprar" style={{ width: "100%" }}>
            <main className="overflow-hidden bg-6">
                <header className="page header">
                    <ModalComprarCertificacionDocumentos show={state.showModalComprar} paquete={state.referenciaPaquete} precio={state.precio} onHide={closeModalComprar} onLoading={props.onLoading} />
                    <div className="content">
                        <div className="row">
                            <div className="col-md-9 col-xs-9">
                                <h1>
                                    <span className="bold d-block">Paquetes de suscripción</span>
                                </h1>
                                <p>Elige el paquete que más se adecue a tus necesidades.</p>
                            </div>
                            <div className="col-md-3 col-xs-3">
                                <h5 className="title">Plan actual:</h5>
                                <h6 className="title">{state.paqueteActual}</h6>
                                <div id="divFechaProximoPago" style={{ display: "none" }}>
                                    <h5 className="title">Próxima fecha de pago:</h5>
                                    <h6 className="title" id="lblFechaProximoPago"></h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </header>
                <div className="container-fluid">
                    <section className="block bg-1">
                        <div className="container py-4">

                            <Tabs>
                                <TabList>
                                    <Tab>Anual</Tab>
                                    <Tab>Mes</Tab>
                                </TabList>
                                <TabPanel>
                                    <div className="row">
                                        <div className="col-md-4 mt-5">
                                            <div className="card text-center">
                                                <div
                                                    className="pricing card-header bg-6-gradient d-flex align-items-center flex-column">
                                                    <h4>Personal</h4>
                                                    <div className="pricing-value">
                                                        <span className="price yearly bold">2500</span>
                                                    </div>
                                                </div>

                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item">Protección de 120 archivos al año</li>
                                                    <li className="list-group-item">Almacenamiento 1.5 GB</li>
                                                    <li className="list-group-item">API disponible</li>
                                                    <li className="list-group-item">Soporte por chat y correo</li>
                                                    <li className="list-group-item strike-through">Creación de organizaciones</li>
                                                    <li className="list-group-item strike-through">Aplicación de marca</li>
                                                    <li className="list-group-item strike-through">Acompañamiento en integración de API</li>
                                                </ul>
                                                <div className="card-body">
                                                    <a href=" #"
                                                        onClick={() => abrirModalPago('DOCBASICANUAL', 2500)}
                                                        className="btn btn-outline-2">Comprar</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mt-5">
                                            <div className="card text-center">
                                                <div className="pricing card-header d-flex align-items-center flex-column"
                                                    style={{ "background": "#fc6d57", "color": "#fff" }}>
                                                    <h4>Business</h4>
                                                    <div className="pricing-value">
                                                        <span className="price yearly bold">476</span>
                                                    </div>
                                                </div>
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item">Protección de 1200 archivos al año</li>
                                                    <li className="list-group-item">Almacenamiento 17 GB</li>
                                                    <li className="list-group-item">API disponible</li>
                                                    <li className="list-group-item">Soporte por chat y correo</li>
                                                    <li className="list-group-item">Creación de organizaciones</li>
                                                    <li className="list-group-item">Aplicación de marca</li>
                                                    <li className="list-group-item strike-through">Acompañamiento en integración de API</li>
                                                </ul>
                                                <div className="card-body">
                                                    <a href=" #"
                                                        onClick={() => abrirModalPago('DOCENTERPRISEMENSUAL', 3299.99)}
                                                        className="btn btn-accent">Comprar</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mt-5">
                                            <div className="card text-center">
                                                <div
                                                    className="pricing card-header bg-6-gradient d-flex align-items-center flex-column">
                                                    <h4>Enterprise</h4>
                                                    <div className="pricing-value">
                                                        <span className="">A cotizar</span>
                                                    </div>
                                                </div>
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item">Protección de archivos ilimitados</li>
                                                    <li className="list-group-item">Almacenamiento ilimitado</li>
                                                    <li className="list-group-item">API/Iframe disponible</li>
                                                    <li className="list-group-item">Soporte prioritario</li>
                                                    <li className="list-group-item">Creación de organizaciones</li>
                                                    <li className="list-group-item">Aplicación de marca</li>
                                                    <li className="list-group-item">Acompañamiento en integración de API</li>
                                                </ul>
                                                <div className="card-body">
                                                    <a href="/contact" className="btn btn-outline-2">Contáctanos</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="row">
                                        <div className="col-md-4 mt-5">
                                            <div className="card text-center">
                                                <div className="pricing card-header bg-6-gradient d-flex align-items-center flex-column">
                                                    <h4>Personal</h4>
                                                    <div className="pricing-value">
                                                        <span className="price bold">11</span>
                                                    </div>
                                                </div>

                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item">Protección de 10 archivos al mes</li>
                                                    <li className="list-group-item">Almacenamiento 1.5 GB</li>
                                                    <li className="list-group-item">API disponible</li>
                                                    <li className="list-group-item">Soporte por chat y correo</li>
                                                    <li className="list-group-item strike-through">Creación de organizaciones</li>
                                                    <li className="list-group-item strike-through">Aplicación de marca</li>
                                                    <li className="list-group-item strike-through">Acompañamiento en integración de API</li>
                                                </ul>
                                                <div className="card-body">
                                                    <a href=" #"
                                                        onClick={() => abrirModalPago('DOCBASICMENSUAL', 869.99)}
                                                        className="btn btn-outline-2">Comprar</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mt-5">
                                            <div className="card text-center">
                                                <div className="pricing card-header d-flex align-items-center flex-column"
                                                    style={{ "background": "#fc6d57", "color": "#fff" }}>
                                                    <h4>Business</h4>
                                                    <div className="pricing-value">
                                                        <span className="price bold">44</span>
                                                    </div>
                                                </div>
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item">Protección de 100 archivos al mes</li>
                                                    <li className="list-group-item">Almacenamiento 17 GB</li>
                                                    <li className="list-group-item">API disponible</li>
                                                    <li className="list-group-item">Soporte por chat y correo</li>
                                                    <li className="list-group-item">Creación de organizaciones</li>
                                                    <li className="list-group-item">Aplicación de marca</li>
                                                    <li className="list-group-item strike-through">Acompañamiento en integración de API</li>
                                                </ul>
                                                <div className="card-body">
                                                    <a href=" #"
                                                        onClick={() => abrirModalPago('DOCENTERPRISEMENSUAL', 3299.99)}
                                                        className="btn btn-accent">Comprar</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mt-5">
                                            <div className="card text-center">
                                                <div
                                                    className="pricing card-header bg-6-gradient d-flex align-items-center flex-column">
                                                    <h4>Enterprise</h4>
                                                    <div className="pricing-value">
                                                        <span className="">A cotizar</span>
                                                    </div>
                                                </div>
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item">Protección de archivos ilimitados</li>
                                                    <li className="list-group-item">Almacenamiento ilimitado</li>
                                                    <li className="list-group-item">API/Iframe disponible</li>
                                                    <li className="list-group-item">Soporte prioritario</li>
                                                    <li className="list-group-item">Creación de organizaciones</li>
                                                    <li className="list-group-item">Aplicación de marca</li>
                                                    <li className="list-group-item">Acompañamiento en integración de API</li>
                                                </ul>
                                                <div className="card-body">
                                                    <a href="/contact" className="btn btn-outline-2">Contáctanos</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </section>
                </div>
            </main>
        </div>
    );
}

export default DocumentosComprar;
