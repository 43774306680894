import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import FileBase64 from "react-file-base64";
import Button from "react-bootstrap/Button";
import Api from "../../util/api";
import Util from "../../util/util";
import swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useLoading from "../../hooks/useLoading";
function NuevoProcesoProduccion(props) {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        proceso: {
            nombre: "",
            descripcion: "",
        },
    });

    async function getFiles(file) {
        startLoading();
        console.log(file);
        if (file.type.search("image/") < 0) {
            await swal.fire("Ups!", 'Solo puedes subir imágenes', "error");
            setState((prevState) => ({
                ...prevState,
                proceso: {
                    ...prevState.proceso,
                    nueva_foto: {
                        ...prevState.nueva_foto,
                        contenido: null,
                    }
                }
            }));
            stopLoading();
            return;
        }
        if (file.size >= 2000000) {
            await swal.fire("Ups!", 'El archivo no puede ser mayores a 2mb. "' +
                file.name +
                '" es un archivo demasiado grande. Te recomendamos comprimirlo', "error");
            setState((prevState) => ({
                ...prevState,
                proceso: {
                    ...prevState.proceso,
                    nueva_foto: {
                        ...prevState.nueva_foto,
                        contenido: null,
                    }
                }
            }));
            stopLoading();
            return;
        }
        console.log();
        setState((prevState) => ({
            ...prevState,
            proceso: {
                ...prevState.proceso,
                nueva_foto: {
                    ...prevState.nueva_foto,
                    contenido: file.base64.split("base64,")[1],
                }
            }
        }));
        stopLoading();
    }

    const handleDateChangeTarjeta = (id, value) => {
        setState((prevState) => ({
            ...prevState,
            proceso: {
                ...prevState.proceso,
                [id]: value,
            }
        }));
    };

    const handleChangeProceso = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            proceso: {
                ...prevState.proceso,
                [id]: value,
            },
        }));
    };

    useEffect(() => {
        if (props.procesoAEditar) {
            setState((prevState) => ({
                ...prevState,
                proceso: props.procesoAEditar,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                proceso: {
                    nombre: "",
                    descripcion: "",
                    nueva_foto: "",
                },
            }));

        }
    }, [props.procesoAEditar]);

    async function clickGuardarProceso() {
        try {
            startLoading();
            var res;
            if (props.procesoAEditar) {
                res = await Api.procesos_produccion.actualizar(props.procesoAEditar._id, state.proceso);
            } else {
                res = await Api.procesos_produccion.crear(props.organizacion, state.proceso);
            }
            stopLoading();
            if (!await Util.requestSuccess(res)) {
                
                await swal.fire("¡Ups!", "Error al guardar proceso", "error");
                
                return;
            }
            await swal.fire("¡Listo!", "El proceso fué guardado con exito", "success");
            console.log(res);

            //props.consultardocumentos();
            //Aqui no es necesario loading = false, props.consultardocumentos() ya lo hace
            props.onHide();
        } catch (e) {
            console.log(e);
            stopLoading();
            await swal.fire("¡Ups!", "Error al guardar proceso", "error");
        }
        props.onHide();
        stopLoading();
    }

    return (
        <div className="NuevoProcesoProduccion">
            <Modal {...props} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo proceso</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <form style={{ width: "100%" }} className="form">
                            <input type="text" placeholder="Nombre" id="nombre" onChange={handleChangeProceso}/>
                            <input type="text" placeholder="Descripción" id="descripcion" onChange={handleChangeProceso}/>
                        </form>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={clickGuardarProceso}>
                        Guardar proceso
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default NuevoProcesoProduccion;