import React from "react";
import ReactDOM from "react-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import "./index.css";
import App from "./App";
import { UserProvider } from './context/UserContext';
import { AuthProvider } from './context/AuthContext';
import { LoadingProvider } from './context/LoadingContext';
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import settings from "./config/settings";

if (settings.env === 'PRO') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

ReactDOM.render(
  <GoogleOAuthProvider clientId={settings.auth.google_oauth_client_id}>
    <UserProvider>
      <LoadingProvider>
        <AuthProvider>
          <React.Fragment>
            <App />
          </React.Fragment>
        </AuthProvider>
      </LoadingProvider>
    </UserProvider>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
