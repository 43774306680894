var Resources = {
    generic_error: "Es posible que tengas problemas con tu conexión a internet. Si no es así, comunicate con el administrador del sistema.",
    months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ],
    datatableDefaultConfig: {
        language: {
            "lengthMenu": "Mostrando  _MENU_  registros por página",
            "zeroRecords": "No se encontraron registros",
            "info": "Mostrando página _PAGE_ de _PAGES_",
            "search": "Buscar",
            "infoEmpty": "No se encontraron registros",
            "infoFiltered": "(Filtrado de _MAX_ registros)",
            "paginate": {
                "previous": "Anterior",
                "next": "Siguiente",
            },
            buttons: {
                copy: 'Copiar',
                copyTitle: 'Copiado al portapapeles',
                copySuccess: {
                    _: '%d registros copiados',
                    1: '1 registro copiado'
                }
            }
        },
    },
    customizePdf: (doc, fontSize) => {
        //doc.defaultStyle.font = 'Arial';
        doc.content.splice( 1, 0, {
            margin: [ 400, -40, 0, 0 ],
            width: 100,
            image: ''
        } );
        if (fontSize) doc.defaultStyle.fontSize = fontSize;
        doc['styles'] = {
            userTable: {
                margin: [0, 15, 0, 15]
            },
            tableHeader: {
                bold: !0,
                fontSize: 8,
                color: 'black',
                fillColor: '#25A7DF',
                alignment: 'center'
            },
            title: {
                color: 'black',
                fontSize: 12,
                alignment: 'center'
            }
        };
    },
    getNivelEducativo(number) {
        try {
            if (number == null) return null;
            switch (number) {
                case 1:
                    return "Primaria";
                case 2:
                    return "Secundaria";
                case 3:
                    return "Medio superior";
                default:
                    return "Error";
            }
        } catch (e) {
            return "Error";
        }
    },
    getNivelEducativoNumber(nivel_educativo) {
        try {
            if (nivel_educativo == null) return null;
            nivel_educativo = nivel_educativo.trim();
            switch (nivel_educativo) {
                case "Primaria":
                    return 1;
                case "Secundaria":
                    return 2;
                case "Bachillerato": case "Medio superior": case "Medio Superior": case "Preparatoria":
                    return 3;
                default:
                    return -1;
            }
        } catch (e) {
            return -1;
        }
    },
    getGenero(number) {
        try {
            number = parseInt(number);
            switch (number) {
                case 0:
                    return "Masculino";
                case 1:
                    return "Femenino";
                default:
                    return "Error";
            }
        } catch (e) {
            return "Error";
        }
    },
    getGeneroNumber(genero) {
        try {
            genero = genero.trim();
            switch (genero) {
                case "Masculino":
                    return 0;
                case "Femenino":
                    return 1;
                default:
                    return -1;
            }
        } catch (e) {
            return -1;
        }
    },
    getGrado(number) {
        try {
            number = parseInt(number);
            switch (number) {
                case 1:
                    return "Primero";
                case 2:
                    return "Segundo";
                case 3:
                    return "Tercero";
                case 4:
                    return "Cuarto";
                case 5:
                    return "Quinto";
                case 6:
                    return "Sexto";
                case 7:
                    return "Egresado";
                default:
                    return "Error";
            }
        } catch (e) {
            return "Error";
        }
    },
    getGradoNumber(grado) {
        try {
            switch (grado) {
                case "Primero":
                    return 1;
                case "Segundo":
                    return 2;
                case "Tercero":
                    return 3;
                case "Cuarto":
                    return 4;
                case "Quinto":
                    return 5;
                case "Sexto":
                    return 6;
                default:
                    return -1;
            }
        } catch (e) {
            return -1;
        }
    },
    getTipoEmprendimiento(number) {
        try {
            number = parseInt(number);
            switch (number) {
                case 1:
                    return "Social";
                case 2:
                    return "Hibrido";
                case 3:
                    return "Financiero";
                default:
                    return -1;
            }
        } catch (e) {
            return -1;
        }
    },
    getPlanteamientoEmprendimiento(number) {
        try {
            number = parseInt(number);
            switch (number) {
                case 1:
                    return "Idea nueva de emprendimiento";
                case 2:
                    return "Continuación del emprendimiento del grado anterior";
                case 3:
                    return "Heredado de otro grupo, equipo o compañero";
                default:
                    return -1;
            }
        } catch (e) {
            return -1;
        }
    },
    getCategoriaEmprendimiento(number) {
        try {
            number = parseInt(number);
            switch (number) {
                case 1:
                    return "General";
                case 2:
                    return "Tecnológico";
                case 3:
                    return "Medio ambiental";
                case 4:
                    return "Equidad de género";
                case 5:
                    return "Prevención de riesgos";
                case 6:
                    return "Otro";
                default:
                    return -1;
            }
        } catch (e) {
            return -1;
        }
    },
    getModalidadEmprendimiento(number) {
        try {
            number = parseInt(number);
            switch (number) {
                case 1:
                    return "Individual";
                case 2:
                    return "En equipo";
                case 3:
                    return "En grupo";
                default:
                    return -1;
            }
        } catch (e) {
            return -1;
        }
    },
    getFase(number) {
        try {
            number = parseInt(number);
            switch (number) {
                case 1:
                    return "Educación social y financiera";
                case 2:
                    return "Emprendimiento";
                default:
                    return -1;
            }
        } catch (e) {
            return -1;
        }
    },
    gradosPrimaria: [
        {
            value: 1,
            texto: "Primero"
        },
        {
            value: 2,
            texto: "Segundo"
        },
        {
            value: 3,
            texto: "Tercero"
        },
        {
            value: 4,
            texto: "Cuarto"
        },
        {
            value: 5,
            texto: "Quinto"
        },
        {
            value: 6,
            texto: "Sexto"
        },
        {
            value: 7,
            texto: "Multigrado"
        },
    ],
    gradosSecundariaBachillerato: [
        {
            value: 1,
            texto: "Primero"
        },
        {
            value: 2,
            texto: "Segundo"
        },
        {
            value: 3,
            texto: "Tercero"
        },
        {
            value: 7,
            texto: "Multigrado"
        },
    ]
};

export default Resources;
